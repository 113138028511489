@use '../../variables';

.container {
    width: 100vw;
    height: 100vh;
    position: fixed;
    background-color: rgba(255, 255, 255, 0.6);
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 2;

    .content {
        height: 100%;
        position: fixed;
        bottom: 0;
        right: 0;
        width: 500px;
        background-color: #fff;
        border: 3px solid #ebebeb;
        border-radius: 5px;
        overflow-y: scroll;

        .content_container {
            width: 65%;
            height: 95%;
            margin-left: 30px;
            margin-top: 30px;

            .row,
            .underlined_row {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                text-align: center;

                h4 {
                    font-size: 1.5rem;
                    font-weight: 700;
                    margin-bottom: 0;
                }

                div {
                    background-color: #ffffff;
                    color: black;
                    font-weight: bold;
                    border: none;
                    font-size: 25px;
                    cursor: pointer;
                }

                .price {
                    font-size: 16px;
                    font-weight: 500;
                }

                .button_container {
                    font-size: 14px;

                    .change_button {
                        border: none;
                        background-color: #ffffff;
                        color: variables.$cart_grey_color;
                    }
                }
            }

            .underlined_row {
                border-bottom: solid 2px variables.$app_grey_color;
            }

            .total_wrapper {
                padding: 20px 0;

                p {
                    margin-bottom: 0;
                }

                .total_text {
                    font-size: 14px;
                    font-weight: 700;
                }

                .total_price {
                    font-size: 16px;
                    font-weight: 700;
                    color: #68902f;
                }
            }

            .section {
                &.pickupMethods {
                    margin-bottom: 20px;
                }

                .section_header {
                    padding-bottom: 15px;
                    border-bottom: solid 2px variables.$app_grey_color;
                    font-size: 14px;
                    font-weight: 700;
                }

                .section_content {
                    border-bottom: solid 2px variables.$app_grey_color;
                    padding-bottom: 20px;

                    .pp_name {
                        font-weight: 500;
                        font-size: 16px;
                    }

                    .pp_address {
                        font-weight: 100;
                        font-size: 14px;
                        color: variables.$cart_grey_color;
                    }

                    .add_prod_message {
                        display: flex;
                        align-items: center;
                        padding: 5px 10px;
                        border-radius: 5px;
                        background-color: #fff7e1;
                        margin-top: 15px;

                        .add_prod_message_icon {
                            color: #f2b23e;
                        }

                        .add_prod_message_text {
                            color: #f2b23e;
                            margin-left: 5px;
                            margin-bottom: 0;
                            font-size: 14px;
                        }
                    }

                    .item_row {
                        width: 100%;
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;

                        p {
                            font-size: 14px;
                            color: #4f4f4f;
                        }

                        .price_item {
                            font-weight: 700;
                            font-size: 14px;
                            color: #121212;
                        }

                        .quantity_wrapper {
                            display: flex;
                            align-items: center;
                            justify-content: space-around;
                            width: 160px;
                            border: solid 1px variables.$app_grey_color;

                            input {
                                width: 40%;
                                text-align: center;
                                border: solid 1px
                                    variables.$app_intense_grey_color;
                                border-radius: 6px;
                            }

                            .minus_button,
                            .plus_button {
                                color: variables.$basic_black_color;
                                height: 25px;
                                width: 25px;
                                border-radius: 50%;
                                background-color: variables.$app_grey_color;
                                cursor: pointer;
                                outline: none;
                                border: none;
                                text-align: center;
                                display: flex;
                                justify-content: center;
                                align-items: center;

                                &.inactive {
                                    opacity: 0.3;
                                    cursor: auto;
                                }

                                p {
                                    margin: 0 0 5px;
                                    user-select: none;
                                }
                            }

                            p {
                                color: variables.$basic_black_color;
                                font-weight: bold;
                                user-select: none;
                                margin-bottom: 0;

                                &.quantity {
                                    font-size: 13px;
                                    font-weight: 700;
                                }
                            }

                            .plus_button {
                                color: variables.$basic_white_color;
                                background-color: variables.$basic_black_color;

                                p {
                                    color: variables.$basic_white_color;
                                }
                            }
                        }

                        .delete_row {
                            display: flex;
                            justify-content: flex-end;
                            align-items: center;
                            height: 40px;
                            width: 20%;
                            cursor: pointer;

                            .delete_text {
                                font-weight: 500;
                                height: 100%;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                font-size: 14px;
                                color: #4f4f4f;
                                margin-left: 10px;
                            }
                        }
                    }

                    .first_row {
                        margin-top: 20px;
                    }

                    .save_button {
                        border: none;
                        background-color: variables.$app_light_green_color;
                        color: #ffffff;
                        padding: 5px 10px;
                        width: 150px;
                    }
                }
            }
        }
    }

    .buttons_container {
        display: flex;
        flex-direction: column;
        height: 20%;
        justify-content: flex-start;
        margin-top: 20px;

        .buttonLink {
            padding: 10px 20px;
            background-color: variables.$app_light_green_color;
            color: #ffffff;
            text-decoration: none;
            border: none;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 55px;
            border-radius: 5px;
            font-size: 13px;
            font-weight: 700;
        }

        .button {
            background-color: variables.$basic_white_color;
            height: 55px;
            margin-bottom: 10px;
            border-radius: 5px;
            font-size: 13px;
            font-weight: 700;
        }
    }
}

.mb30 {
    margin-bottom: 30px;
}

@media screen and (max-width: 768px) {
    .container {
        .content {
            .content_container {
                margin-top: 170px;
            }
        }
    }
}

@media screen and (max-width: 600px) {
    .container {
        .content {
            width: 100vw;
            .content_container {
                width: 85%;
            }
        }
    }
}

@use '../../variables';

.register_container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .form_container {
        display: flex;
        flex-direction: column;
        padding: 3%;
        border: 1px solid variables.$app_intense_grey_color;
        margin-bottom: 2%;

        .navigate_buttons {
            display: flex;
            text-transform: uppercase;
            font-size: 18px;
            font-family: variables.$second_font_family;
            font-weight: bold;
            margin-bottom: 3%;
            margin-left: 1%;

            .login {
                margin-right: 3%;
                opacity: 0.5;
                color: variables.$app_intense_grey_color;
                cursor: pointer;
            }

            .register {
                color: variables.$app_light_green_color;
                text-decoration: underline 4px;
                text-underline-offset: 4px;
                cursor: pointer;
            }
        }

        .form {
            display: flex;

            input:-webkit-autofill {
                -webkit-background-clip: text;
            }

            input,
            select {
                border: 2px solid variables.$app_intense_grey_color;
                border-radius: 3px;

            }

            input[type=radio] {
                accent-color: variables.$app_light_green_color;
                width: 15px;
                height: 15px;
            }

        }
    }
}

@media screen and (max-width: 1310px) {
    .register_container {
        .form_container {
            .form {
                display: flex;
                flex-direction: column;
                padding-right: 3%;

            }
        }

        .navigate_buttons {
            padding-left: 1%;
        }
    }
}
.MuiFormControl-root {
    width: 100% !important;
}


.footer-inner {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 1300px;
    width: 100%;
    padding: 0 10px;

    .footer-column {
        width: 23%;
        h2 {
            font-size: 1.5rem;
        }

        .title-paragraphe {
            font-weight: 600;
            font-size: 1rem;
        }

        .contact-info{
            display: flex;
            align-items: center;
            margin-bottom: 10px;
            img {
                margin-right: 10px;
            }
        }
        a {
            color: #fff;
        }
        img {
            max-width: 100%;
        }
        .link-list {
            list-style-type: none;
            padding: 0;
            li {
                padding-bottom: 10px;
            }
        }

        &.falow-us {
            img {
                margin-right: 10px;
            }
        }
    }
}

@media screen and (max-width: 1024px) {
    .footer-inner {
        padding: 0 20px;
        .footer-column {
            width: 48%;
        }
    }
}

@media screen and (max-width: 576px) {
    .footer-inner {
        flex-direction: column;
        padding: 0 20px;
        .footer-column {
            width: 100%;

            .title-paragraphe {
                font-size: 1.5rem;
                font-weight: 500;
            }
        }
    }
}
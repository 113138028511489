@use '../../../../variables';

.wrapper {
    height: 98vh;
    overflow-y: hidden;

    .main_container {
        margin-left: 50%;
        padding: 2%;
        margin-top: 15%;
        width: 25%;
        transform: translateX(-50%);
        padding-top: 2%;
        border-radius: 4;
        padding-bottom: 2%;
        border-radius: 6px;
        border: 2px solid variables.$app_intense_grey_color;

        .form_container {
            display: flex;
            align-items: center;
            flex-direction: column;
            gap: 5px;

            .form_row {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 100%;
                font-family: variables.$font-family;

                .row_name {
                    width: 100%;
                    margin-left: 3%;
                    margin-right: 2%;
                    font-weight: bold;
                    margin-top: 5%;
                    margin-bottom: 1%;
                    font-size: 13px;
                }

                .row_input {
                    width: 100%;
                    padding: 3%;
                    border: 2px solid variables.$app_intense_grey_color;
                    border-radius: 3px;
                    font-weight: bold;

                    &:-webkit-autofill {
                        -webkit-background-clip: text;
                    }
                }
            }

            .link_container {
                text-decoration: underline;
                align-self: flex-end;

                a {
                    color: variables.$basic_black_color;
                    font-size: 13px;
                    font-family: variables.$second_font_family;
                    text-decoration: none;
                }
            }

            .submit_button {
                min-width: 10rem;
                min-height: 3rem;
                border-radius: 4px;
                outline: none;
                text-transform: uppercase;
                border: none;
                margin-top: 8%;
                cursor: pointer;
                font-weight: bold;
                color: variables.$basic_white_color;
                background-color: variables.$app_light_green_color;
            }

            .errors {
                font-size: 13px;
                color: variables.$basic_red_color;
                font-weight: bold;
            }
        }
    }
}

@media screen and (max-width: 1370px) {
    .wrapper {
        width: 100%;
        overflow-x: hidden;

        .main_container {
            width: 80%;
        }
    }
}

@media screen and (max-width: 1370px) {
    .wrapper {
        width: 100%;
        overflow-x: hidden;

        .main_container {
            width: 80%;
        }
    }
}

.header {
    display: flex;
    justify-content: center;
    align-items: center;

}
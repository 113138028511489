@use '../../../../variables';

.container {
    width: 100%;
    min-height: 35vh;
    display: flex;
    flex-wrap: wrap;
    margin-top: 2%;

    .single_point_panel {
        width: 26%;
        height: 35vh;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        margin-left: 2%;
        border-radius: 6px;
        align-items: center;
        border: solid 1px variables.$app_grey_color;
        overflow-y: auto;

        .pp_header {
            font-size: 16px;
            font-weight: bold;
            text-align: left;
            width: 90%;
            margin-left: 15px;
        }

        .pp_name {
            font-size: 15px;
        }

        .pp_address {
            font-size: 12px;
        }

        .address {
            width: 70%;
        }


        .sessions_container {
            margin-bottom: 10%;
            height: 50%;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            width: 100%;

            .date_container {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: flex-start;
                flex-direction: column;
                display: flex;
                justify-content: center;
                align-items: center;

                .pp_date {
                    width: 70%;
                    font-size: 14px;
                }
            }


        }

    }

    .single_point_panel:hover,
    .selected_pickup_point {
        background-color: variables.$border_color;
        border: solid 1px variables.$input_option_accent_color;
        cursor: pointer;
    }

    @media (max-width: 1400px) {
        .single_point_panel {
            .pp_header {
                font-size: 13px;
            }

            .pp_name {
                font-size: 12px;
            }

            .pp_address {
                font-size: 11px;
            }
        }
    }

    @media (max-width: 640px) {
        .single_point_panel {
            width: 33%;

            .pp_header {
                font-size: 11px;
            }

            .pp_name {
                font-size: 10px;
            }

            .pp_address {
                font-size: 9px;
            }
        }
    }

    @media (max-width: 450px) {
        .single_point_panel {
            width: 48%;

            .pp_header {
                font-size: 9px;
            }

            .pp_name {
                font-size: 8px;
            }

            .pp_address {
                font-size: 7px;
            }
        }
    }

}
@use '../../variables';

.main_container {
    width: 100%;
    padding: 5%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 4vh;
    align-items: center;
    font-family: variables.$font_family;

    .title {
        font-size: 24pt;
    }

    .methods_wrapper {
        width: 70%;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        gap: 20px;
        flex-wrap: wrap;
        padding: 20px;

        .item {
            padding: 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
            border-radius: 10px;
            min-height: 100px;
            min-width: 300px;
            box-shadow: -1px 1px 8px 0px variables.$app_light_green_color;
            transition: transform 0.3s ease;

            &:hover {
                transform: scale(1.02);
            }

            p {
                color: variables.$app_light_green_color;
                font-weight: bold;
                text-transform: uppercase;
                text-align: center;
            }

            img {
                height: 80%;
            }
        }
    }
}
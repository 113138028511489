@use '../../../variables';


.footer_container {
    background: variables.$spizarnia24_footer_background_color;
    color: variables.$basic_white_color;
    display: flex;
    flex-direction: column;
    font-family: variables.$font-family;
    justify-content: center;
    align-items: center;
    width: 100vw;

    p,
    a {
        font-size: 15px;
    }

    .columns {
        width: 70%;
        display: flex;
        justify-content: center;

        .footer_section {
            width: 25%;
            margin: 2% 1%;
            display: flex;
            flex-direction: column;

            a {
                text-decoration: none;
                color: variables.$basic_white_color;
            }
            h3 {
                font-weight: 600;
                margin-bottom: 1.5rem;
            }
        }
    }


    .second_row {
        height: 70px;
        width: 100%;
        max-width: 1300px;
        padding: 0 10px;
        background-color: variables.$spizarnia24_footer_background_color;
        display: flex;
        justify-content: center;

        .copyright {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 100%;
            width: 100%;

            .left {
                width: 60%;
                height: 100%;
                display: flex;
                align-items: center;

                p {
                    color: variables.$basic_grey_color;
                    margin: 0;
                }
            }

            .right {
                width: 20%;
                display: flex;
                justify-content: flex-end;
                align-items: center;

                img {
                    height: 30px;
                }
            }
        }
    }
}

a {
    text-decoration: none;
}

p {
    font-size: 12px;
}


@media screen and (max-width: variables.$first_breakpoint) {
    .footer_container {
        .columns {
            width: 100%;
        }
    }
}

@media screen and (max-width: variables.$second_breakpoint) {
    .footer_container {
        width: 100%;
    }

}

@media screen and (max-width: variables.$third_breakpoint) {
    .footer_container {
        .columns {
            width: 100%;
            flex-wrap: wrap;

            .footer_section {
                min-width: 40%;
            }
        }
    }
}


@media screen and (max-width: variables.$fourth_breakpoint) {
    .footer_container {
        .columns {
            width: 100%;
            padding: 0;
            flex-direction: column;

            .footer_section {
                width: 96%;
                padding: 2%;
                h3 {
                    font-weight: 600;
                }
            }
        }

        .second_row {
            width: 100%;
            height: 100%;
            padding: 0 20px;
            margin-bottom: 30px;

            .copyright {
                flex-direction: column;
                width: 100%;
                height: 100%;
                

                .left {
                    width: 100%;
                    height: 50%;
                    justify-content: center;
                    p {
                        margin-bottom: 15px;
                    }
                }

                .right {
                    width: 100%;
                    height: 50%;
                    justify-content: center;
                }
            }

        }
    }

}
@use "../../../../variables";

.info_container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 2%;
    margin-bottom: 2%;
    margin: 3% auto;
    max-width: 1300px;
    width: 100%;

    .carousel {
        width: 100%;
        .carousel_wrapper {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;
            .item {
                display: flex;
                align-items: center;
                box-sizing: border-box;
                height: 4rem;
                text-decoration: none;
                color: variables.$basic_black_color;
                opacity: 0;
                transform: translateX(-50px);
                animation: fadeInSlide 0.5s forwards;
                transition: transform 0.3s ease, opacity 0.3s ease;
                animation-delay: 0.2s;
                will-change: transform, opacity;

                &:nth-child(1) {
                    animation-delay: 0.1s;
                }

                &:nth-child(2) {
                    animation-delay: 0.2s;
                }

                &:nth-child(3) {
                    animation-delay: 0.3s;
                }

                &:nth-child(4) {
                    animation-delay: 0.4s;
                }

                img {
                    filter: variables.$convert_icon_to_app_light_green;
                    width: 60px;
                    height: auto;
                }

                .item_description {
                    display: flex;
                    flex-direction: column;
                    height: fit-content;
                    font-family: variables.$second_font_family;
                    margin-left: 10px;
                    .top_part {
                        font-weight: bold;
                        color: variables.$app_green_color;
                        height: 18%;
                        margin-bottom: 0;
                    }

                    .bottom_part {
                        font-size: 0.8rem;
                        margin: 0;
                    }
                }
            }
        }
    }
}

@keyframes fadeInSlide {
    0% {
        opacity: 0;
        transform: translateX(-50px);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

.button_container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15%;

    button {
        background-color: transparent;
        border: none;
        font-size: 22px;
        color: variables.$app_intense_grey_color;
        font-weight: bold;
        cursor: pointer;
        transition: color 0.3s ease;

        &:hover {
            color: variables.$app_green_color;
        }
    }
}

@media screen and (max-width: 1300px) {
    .info_container {
        .item {
            img {
                width: 65px;
                height: 65px;
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .info_container {
        display: flex;
        margin: 10% auto;

        .button_container {
            width: 15%;
            display: flex;
            justify-content: center;
            align-items: center;

            button {
                background-color: transparent;
                border: none;
                font-size: 22px;
                color: variables.$app_intense_grey_color;
                font-weight: bold;
                cursor: pointer;
                transition: color 0.3s ease;

                &:hover {
                    color: variables.$app_green_color;
                }
            }
        }

        .carousel {
            width: 70%;

            .carousel_wrapper {
                display: flex;
                justify-content: center;
                align-items: center;

                .item {
                    width: 70%;
                    img{
                        width: 80px;
                    }
                    .item_description {
                        margin-left: 20px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 576px) {
    .main_container .info_container .item {
        img {
            width: 35px;
            height: auto;
        }

        .item_description {
            font-size: 12px;
        }
    }
}

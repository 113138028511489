@use '../../../../variables';

.main_container {
    width: 100%;

    .upper_part {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding-left: 1%;
        margin-top: 2%;

        .left {
            text-transform: uppercase;
            font-size: 20px;
            font-weight: bold;
        }

        .right {
            display: flex;
            justify-content: space-around;
            width: 80px;
        }
    }

    .items {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding-bottom: 1%;
        padding-top: 2%;
        padding-left: 7%;
        padding-right: 7%;
        width: 100%;

        .item_wrapper {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            height: 100%;
            width: 250px;

            .image_container {
                width: 80px;
                height: 80px;
                cursor: pointer;

                a {
                    img {
                        width: 100%;
                        height: 100%;
                        border-radius: 50%;
                        object-fit: cover;
                    }
                }

                .grey_circle {
                    width: 100%;
                    height: 100%;
                    border-radius: 50%;
                    background-color: variables.$app_intense_grey_color;
                }
            }

            .name_container {
                text-transform: uppercase;
                font-family: variables.$second_font_family;
                font-weight: bold;
                padding-top: 30px;
                padding-bottom: 10px;
                width: 100%;
                text-align: center;
            }
        }
    }
}

@media screen and (max-width: 855px) {
    .main_container {

        .upper_part {
            margin-bottom: 30px;
        }

        .items {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 2% 0;

            .item_wrapper {
                .name_container {
                    padding-bottom: 15%;
                    font-size: 14px;
                }
            }
        }
    }
}
@use '../my-account-info/MyAccountInfo.module.scss';
@use '../../../../variables';

.main_container {
    margin-left: 2%;

    .basic_info {
        display: flex;
        gap: 20%;

        .basic_data {
            font-weight: bold;
            color: variables.$app_light_green_color;
        }
    }

    .title {
        font-size: larger;
        font-size: 28px;
        font-weight: bold;
        text-transform: uppercase;
        color: variables.$app_green_color;
    }

    .subtitle {
        font-size: larger;
        font-size: 22px;
        font-weight: bold;
        text-transform: uppercase;
        color: variables.$app_green_color;
    }

    .return_button {
        background-color: variables.$app_grey_color;
        width: 240px;
        height: 50px;
        border-radius: 3px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: variables.$font-family;
        cursor: pointer;
        margin-top: 3%;
        margin-bottom: 3%;
        text-transform: uppercase;
        font-weight: bold;
        color: variables.$basic_black_color;
        font-size: 12px;
        border: none;
    }


    .column {
        text-align: center;
        width: 25%;
    }

    .product_column {
        display: flex;
        width: 250px;
        align-items: center;
        margin-left: 20%;
        gap: 5px;

        .image_container {
            border: 1px solid variables.$basic_grey_color;
            border-radius: 3px;
            width: 70px;
            height: 70px;
            padding: 3px;

            img {
                width: 100%;
                height: 100%;
            }
        }

        a {
            display: flex;
            justify-content: center;
            align-items: center;
            text-decoration: none;
            color: variables.$basic_black_color;
            font-size: 12px;

            &:hover {
                cursor: pointer;
                color: variables.$app_light_green_color;
            }
        }
    }

    .summary_row {
        padding: 10px;
        display: flex;
        width: 100%;
        justify-content: space-between;
        border-bottom: 1px solid variables.$basic_grey_color;

        .summary_label {
            font-size: larger;
            font-size: 18px;
            font-weight: bold;
            text-transform: uppercase;
            color: variables.$app_green_color;
            margin: 0;
        }

        .summary_value {
            font-weight: bold;
            color: variables.$app_light_green_color;
            font-size: 22px;
            margin: 0;
        }
    }

    .footer {
        border-top: 2px solid variables.$basic_grey_color;
        padding-top: 10px;

        .order_info_container {
            display: flex;
            width: 100%;

            .order_info_section {
                flex: 1;

                .order_info_section_title {
                    font-size: larger;
                    font-size: 18px;
                    font-weight: bold;
                    text-transform: uppercase;
                    color: variables.$app_green_color;
                }
            }
        }
    }

}
@use '../../variables';

.main_container {

    .image_carousel {
        height: 400px;
    }



    .categories_and_products {
        display: grid;
        grid-template-columns: 300px 1000px;
        max-width: 1300px;
        width: 100%;
        margin: 0 auto;

        &.product_list {
            margin-top: 50px;
        }

        .width-container {
            width: 100%;
        }
    }

    .newest_products {
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .producers_list {
        max-width: 1300px;
        width: 100%;
        display: flex;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
    }

    .products_of_a_day {
        max-width: 1300px;
        width: 100%;
        display: flex;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
    }

}

@media screen and (max-width: 1300px) {
    .main_container {

        .image_carousel {
            height: 400px;
        }

        .categories_and_products {
            display: flex;
        }



        .products_container {
            width: 75%;
        }

        .products_searched_container {
            width: 75%;
        }

        .info_container {
            padding: 0 20px;
        }

        .categories_and_products {
            padding: 0 20px;
        }

        .producers_and_promo {
            padding: 0 20px;
        }
    }
}

@media screen and (max-width: 767px) {
    .main_container {
        .info_container {
            .item {
                width: 50%;
            }
        }

        .image_carousel {
            height: 250px;
        }

        .categories_and_products {
            flex-direction: column;
            margin-top: 20px;
        }

        .products_container {
            width: 100%;
            margin-top: 20px;
        }

        .products_searched_container {
            width: 100%;
        }
    }
}

@media screen and (max-width: 576px) {
    .main_container .info_container .item {
        img {
            width: 35px;
            height: auto;
        }

        .item_description {
            font-size: 12px;
        }
    }
}

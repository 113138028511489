@use '../../variables';

.container {
    width: 100vw;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    .decor {
        width: 100%;
        height: 100px;
        background-color: variables.$app_light_green_color;
        display: flex;
        align-items: center;
        justify-content: center;

        .header {
            text-transform: uppercase;
            font-weight: bold;
            font-size: 25px;
            color: #fff;
            text-align: center;
        }
    }

    .content {
        display: flex;
        height: 100%;
        width: 60%;
        justify-content: flex-end;
        margin-top: 2%;
        padding: 10px;

        .left_side {
            display: flex;
            flex-direction: column;
            height: 70%;
            width: 80%;
            margin-right: 10%;
            margin-top: 5%;

            .message {
                font-size: 16px;
                padding-bottom: 3%;
            }
        }

        .right_side {
            height: 90%;
            width: 40%;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: variables.$app_grey_color;
            padding: 3%;
        }
    }

    button {
        background-color: variables.$app_light_green_color;
        color: white;
        width: 100%;
        height: 60px;
        border: none;
    }
}

.bold {
    font-weight: bold;
}

@media (max-width: 1200px) {
    .container .content {
        flex-direction: column;
        align-items: center;
    }

    .container .content .left_side {
        width: 100%;
        margin-top: 2%;
        height: auto;
    }

    .container .content .right_side {
        width: 100%;
        margin-top: 5%;
        height: auto;
    }
}

@media (max-width: 768px) {
    .container {
        height: auto;
        padding: 20px;
    }

    .container .decor {
        height: 80px;
    }

    .container .decor .header {
        font-size: 17px;
        text-align: center;
    }

    .container .content {
        width: 90%;
        margin-top: 5%;
    }

    .container .content .left_side {
        height: auto;
        margin-top: 5%;
        .message {
            font-size: 14px;
        }
    }

    .container .content .right_side {
        height: auto;
        padding: 5%;
    }

    button {
        height: 50px;
    }
}

@media (max-width: 480px) {
    .container {
        height: auto;
        padding: 10px;
    }

    .container .decor {
        height: 60px;
    }

    .container .decor .header {
        font-size: 13px;
        text-align: center;
    }

    .container .content {
        width: 100%;
        margin-top: none;
    }

    .container .content .left_side {
        width: 100%;
        margin-top: 10%;
        .message {
            font-size: 12px;
            padding-left: 15px;
        }
    }

    .container .content .right_side {
        width: 100%;
        padding: 10%;
    }

    button {
        height: 40px;
    }
}

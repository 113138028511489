@use "../../../variables";

.container {
    width: 100%;

    .main_photo {
        img {
            width: 90%;
            transition: transform 0.2s ease;
        }
    }

    .other_photos {
        margin-top: 10px;
        width: 90%;
        display: flex;
        flex-direction: row;

        img {
            width: 20%;
            transition: transform 0.2s ease;
            margin-right: 10px;
        }
    }

    .modal {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.7);
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1000;
    }

    .modal_content {
        background-color: variables.$basic_white_color;
        padding: 20px;
        border-radius: 10px;
        box-shadow: variables.$gallery_box_shadow;
        width: 50vw;
        height: 70vh;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        img {
            width: 70%;
            height: 80%;
        }
    }

    .modal_image {
        max-width: 100%;
        max-height: 80vh;
        display: block;
        margin: 0 auto;
    }

    .close_button,
    .prev_button,
    .next_button {
        padding: 10px 20px;
        margin: 10px;
        color: variables.$basic_white_color;
        background-color: variables.$app_light_green_color;
        border: none;
        border-radius: 5px;
        cursor: pointer;
    }

    .close_button {
        position: absolute;
        top: 10px;
        right: 10px;
    }
}
@use '../../../../variables';

.container{
    width: 100%;
    display: flex;
    flex-direction: column;
    font-family: variables.$font-family;


    .first_row{
        width: 60%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        text-align: center;
        margin-top: 20px;
        .text{
            height: 45px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        button{
            background-color: variables.$app_light_green_color;
            color: variables.$basic_white_color;
            cursor: pointer;
            border-radius: 3px;
            text-transform:uppercase;
            font-weight: bold;
            font-size: 12px;
            border: none;
            height: 45px;
            width: 140px;
        }

        input{
            width: 100px;
            border: solid 1px variables.$app_intense_grey_color;
        }
    }
    .second_row{
        margin-top: 15px;
    }
    .third_row{
        width: 100%;
        display: flex;
        justify-content: flex-end;
        margin-bottom: 10px;
        margin-top: 30px;
        button{
            width: 140px;
            background-color: variables.$app_light_green_color;
            height: 45px;
            border-radius: 3px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            font-weight: bold;
            font-size: 12px;
            text-transform:uppercase;
            color: variables.$basic_white_color;
            border: none;

            &:disabled {
                background-color: grey;
                color: lightgrey;
                cursor: not-allowed;
                opacity: 0.6;
            }
        }
    }

    .green_text{
        color: variables.$app_light_green_color;
    }

    .red_text{
        color: variables.$basic_red_color;
    }
}

@media screen and (max-width: 1300px) {
    .container {
        .first_row{
            width: 70%;
        }
    }
}

@media screen and (max-width: 1100px) {
    .container {
        .first_row{
            width: 80%;
        }
    }
}

@media screen and (max-width: 1000px) {
    .container {
        .first_row{
            width: 100%;
        }
    }
}

@media screen and (max-width: 550px) {
    .container {
        .first_row{
            flex-direction: column;
            height: 15vh;
        }
    }
}
@use '../../../variables';

.filtersContainer {
    border: 1px solid variables.$basic_grey_color;
    margin-bottom: 5%;
    padding: 3%;
    border: none;
}

.heading {
    text-transform: uppercase;
    color: variables.$app_light_green_color;
}

label {
    font-size: 0.8rem;
    font-weight: normal;
    white-space: nowrap;
    margin: 0;
    text-transform: uppercase;
    font-weight: bold;
    color: variables.$app_dark_green_color;
}

.secondRow {
    display: flex;
    gap: 2%;
    align-items: center;
    flex-wrap: wrap;

    .filter_option_input {
        accent-color: variables.$app_light_green_color;
    }

    select {
        width: 100%;
        border: none;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
        outline: none;
        color: variables.$app_dark_green_color;
    }

    option {
        color: black;
    }

    .select_container {
        width: 100%;
    }
}

.custom_underline {
    position: relative;
    display: flex;
    justify-content: flex-start;

    &:after {
        content: "";
        position: absolute;
        bottom: 0;
        width: 10%;
        height: 4px;
        background-color: variables.$app_light_green_color;
    }

    padding-bottom: 8px;
}

.warning {
    font-size: large;
    font-weight: bold;
    color: variables.$basic_red_color;
}

@media (max-width: 767px) {
    .warning {
        display: none;
    }
}

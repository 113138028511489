@use "../../../variables";

.remember_me {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    font-size: 14px;

    input[type=checkbox] {
        accent-color: variables.$app_light_green_color;
    }
}
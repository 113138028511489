@use '../../variables';

.cart_container {
    .up_decor {
        width: 100%;
        height: 100px;
        background-color: variables.$app_light_green_color;
        margin-bottom: 30px;
    }
}

.main_container {
    font-family: variables.$font_family;
    max-width: 1300px;
    width: 100%;
    display: grid;
    grid-template-columns: 3fr 1fr;
    grid-auto-rows: auto;
    margin: 0 auto 90px auto;

    .header_container {
        display: flex;
        align-items: center;
        font-weight: bold;
        text-transform: uppercase;
        font-size: 12px;
        margin-bottom: 20px;
        gap: 15px;

        .title {
            margin: 0;
            padding: 0;
            font-size: 24px;
        }
    }

    .delivery_method {
        .no_pickup_points {
            margin-top: 10px;
            font-size: 18px;
            font-weight: bold;
        }

        .red {
            color: red;
        }

        .delivery_details {
            padding-bottom: 20px;
            margin-bottom: 20px;
        }

        .radio_wrapper {
            width: 100%;

            &.error {
                border: solid 1px variables.$basic_red_color;
            }

            .radio_with_label {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                font-size: 14px;

                .delivery_price {
                    color: variables.$app_light_green_color;
                    margin-left: 5px;
                    font-weight: 700;
                }

                input[type='radio'] {
                    accent-color: variables.$app_light_green_color;
                    margin-right: 50px;
                }

                &:first-child {
                    .delivery_item {
                        border-top: 1px solid variables.$app_grey_color;
                    }
                }

                .delivery_item {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    width: 100%;
                    padding: 10px 0;
                    border-bottom: 1px solid variables.$app_grey_color;
                }
            }

            .button_row{
                width: 100%;
                display: flex;
                justify-content: flex-end;
                margin-bottom: 10px;
                margin-top: 30px;
                button{
                    background-color: variables.$app_light_green_color;
                    width: 20%;
                    height: 45px;
                    border-radius: 3px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                    font-weight: bold;
                    font-size: 12px;
                    text-transform:uppercase;
                    color: variables.$basic_white_color;
                    border: none;

                    &:disabled {
                        background-color: grey;
                        color: lightgrey;
                        cursor: not-allowed;
                        opacity: 0.6;
                    }
                }
            }
        }
    }

    .empty_cart {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-left: 30%;

        .buy_button {
            background-color: variables.$app_light_green_color;
            width: 40%;
            height: 50px;
            border-radius: 3px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            text-transform: uppercase;
            font-weight: bold;
            font-size: 12px;
            color: variables.$basic_white_color;
            margin-bottom: 35px;
            border: none;
        }
    }

    .cart_title {
        font-size: 24px;
        font-weight: 600;
        text-transform: uppercase;
        color: #121212;
        margin-bottom: 30px;
        margin-top: 25px;
    }

    .table_container {
        padding-right: 70px;

        table {
            width: 100%;
            margin-bottom: 30px;

            tr {
                border-bottom: 1px solid variables.$basic_grey_color;
            }

            thead {
                text-transform: uppercase;

                th {
                    font-weight: 700;
                    font-size: 12px;
                    color: #000;
                    text-align: center;

                    &.align_left {
                        text-align: left;
                    }

                    &.product {
                    }
                }

                .close {
                    width: 5%;
                }

                .product {
                    width: 30%;
                }

                .price {
                    width: 15%;
                }

                .quantity {
                    width: 20%;
                }

                .saleUnitName {
                    width: 5%;
                }

                .totalPrice {
                    width: 30%;
                }
            }

            tbody {
                margin-bottom: 30px;

                td {
                    padding-top: 30px;
                    padding-bottom: 30px;
                    font-size: 14px;
                }

                .product_column {
                    display: flex;
                    gap: 20px;
                    align-items: center;
                    .cross_icon {
                        color: variables.$app_intense_grey_color;
                        cursor: pointer;
                    }
                    .product_image_and_name {
                        display: flex;
                        flex-direction: row;
                        margin-left: 40px;
                        a {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            text-decoration: none;
                            color: variables.$basic_black_color;
                            font-size: 14px;
                            margin-left: 5px;

                            &:hover {
                                cursor: pointer;
                                color: variables.$app_light_green_color;
                            }
                        }
                    }
                }

                .price_column {
                    margin-bottom: 0;
                    text-align: center;
                }

                .quantity_column {
                    padding: 0;

                    .wrapper {
                        display: flex;
                        gap: 15px;
                        align-items: center;
                        justify-content: space-around;
                        width: 100%;
                        height: 100%;

                        input {
                            text-align: center;
                            border: solid 1px variables.$app_intense_grey_color;
                            width: 70px;
                            border-radius: 6px;
                        }

                        .minus_button {
                            color: variables.$basic_black_color;
                            height: 25px;
                            width: 25px;
                            border-radius: 50%;
                            background-color: variables.$app_grey_color;
                            cursor: pointer;
                            font-weight: bold;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            border: none;

                            &.inactive {
                                opacity: 0.3;
                                cursor: auto;
                            }

                            p {
                                margin-bottom: 0;
                                font-size: 16px;
                                line-height: 16px;
                                margin-top: -2px;
                            }
                        }

                        .plus_button {
                            color: variables.$basic_white_color;
                            height: 25px;
                            width: 25px;
                            background-color: variables.$basic_black_color;
                            border-radius: 50%;
                            cursor: pointer;
                            font-weight: bold;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            text-align: center;

                            &.inactive {
                                opacity: 0.3;
                                cursor: auto;
                            }
                        }

                        .quantity_indicator {
                            font-weight: bold;
                            font-family: variables.$second_font_family;
                            font-size: 13px;
                            margin-bottom: 0;
                        }
                    }
                }

                .saleUnitName_column {
                    width: 5%;
                    text-align: center;
                }

                .totalPrice_column {
                    width: 30%;
                    font-weight: bold;
                    color: variables.$app_light_green_color;
                    font-size: 16px;
                    text-align: center;
                }
            }
        }
    }

    .side_block {
        width: 100%;
        max-height: 630px;
        border: 2px solid variables.$app_grey_color;
        display: flex;
        flex-direction: column;
        padding: 0 40px 25px;
        border-radius: 5px;

        p {
            margin-bottom: 0;
        }

        .label {
            font-family: variables.$second_font_family;
            font-size: 14px;
            font-weight: 700;
        }

        .products_price {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 12px;
            padding-bottom: 20px;
            margin-bottom: 20px;
            color: #000;
            border-bottom: 1px solid variables.$app_grey_color;

            .number {
                color: variables.$app_light_green_color;
                font-size: 14px;
                font-weight: 700;
            }
        }

        .total_with_VAT {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 14px;
            padding-bottom: 20px;
            margin-bottom: 20px;
            color: #000;
            border-bottom: 1px solid variables.$app_grey_color;

            .total {
                font-family: variables.$second_font_family;
                font-weight: bold;
                color: variables.$app_light_green_color;
                font-size: 16px;
                font-weight: 700;
            }
        }

        .buy_button {
            background-color: variables.$app_light_green_color;
            width: 100%;
            height: 50px;
            border-radius: 3px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            text-transform: uppercase;
            font-weight: bold;
            font-size: 12px;
            color: variables.$basic_white_color;
            margin-bottom: 35px;
            border: none;

            &:disabled {
                background-color: grey;
                color: lightgrey;
                cursor: not-allowed;
                opacity: 0.6;
            }
        }

        .return_button {
            width: 100%;
            height: 50px;
            border-radius: 3px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            margin-top: 3%;
            margin-bottom: 3%;
            text-transform: uppercase;
            font-weight: bold;
            color: variables.$basic_black_color;
            font-size: 12px;
            margin-bottom: 10px;
            border: 1px solid variables.$basic_black_color;
        }

        .payments {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: auto;
            color: #000;
            font-size: 12px;

            img {
                width: 30%;
                cursor: pointer;
            }
        }
    }
}

.pickup_points_container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 2%;

    .label {
        color: variables.$app_green_color;
        font-weight: bold;
        margin-right: 5px;
    }

    .select_wrapper {
        width: 200px;
        align-self: flex-start;
    }
}

.centered_td {
    text-align: center;
}

.selling_sessions_table {
    margin-top: 20px;
}

.errorText {
    color: variables.$basic_red_color;
}

@media screen and (max-width: 1300px) {
    .main_container {
        padding: 0 20px;
    }
}

@media screen and (max-width: 920px) {
    .main_container {
        display: flex;
        flex-direction: column;
    }
}

@media screen and (max-width: 600px) {
    .main_container {
        .table_container {
            padding-right: 0px;
            table {
                tr {
                    .price {
                        display: none;
                    }
                }
                thead {
                    th {
                        font-size: 10px;
                    }
                }
                tbody {
                    width: 100%;
                    td,
                    div {
                        font-size: 12px;
                    }
                    tr {
                        .product_column {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            width: 120px;
                            .product_image_and_name {
                                flex-direction: column;
                                width: 70%;
                                align-items: center;
                                margin-left: 0px;
                                a {
                                    margin-left: 0px;
                                    margin-top: 5px;
                                    text-align: center;
                                }
                            }
                        }

                        .quantity_column {
                            width: 70px;
                            .wrapper {
                                width: 140px;
                                gap: 0px;
                                input {
                                    width: 50px;
                                }
                            }
                            .minus_button,
                            .plus_button {
                                width: 20px;

                                p {
                                    font-size: 12px;
                                }
                            }
                        }
                        .totalPrice_column {
                            width: 20vw;
                            font-size: 12px;
                        }

                        .price_column {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

@use '../../../../variables';

.main_container {
    max-width: 83vw;

    .upper_part {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-left: 30px;
        margin-top: 20px;
        margin-bottom: 25px;

        .left {
            display: flex;
            flex-direction: column;
            text-transform: uppercase;
            font-size: 14px;
            font-weight: bold;
            color: variables.$app_green_color;
            font-family: variables.$second_font_family;

            .green_horizontal_line {
                height: 3px;
                width: 50px;
                background-color: variables.$app_light_green_color;
            }
        }

        .right {
            display: flex;
            justify-content: space-around;
            width: 80px;
        }
    }

    .items {
        display: flex;
        justify-content: flex-end;
        width: 100%;
        padding-left: 23px;
        height: 100%;

        .swiper_container {
            width: 100%;
        }

        .mySwiper {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .swiper_slide {
            height: 100%;
            display: flex !important;
            align-items: flex-start;
            flex-shrink: 0 !important;
        }
    }
}

@media screen and (max-width: 767px) {
    .main_container {
        max-width: 100%;

        .upper_part {
            padding-left: 0;
        }

        .items {
            padding-left: 0;
        }
    }
}

@media screen and (max-width: 450px) {
    .main_container {
        .items {
            .swiper_container {
                width: 100%;
                padding-left: 2%;
                padding-right: 3%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
            }
        }
    }
}

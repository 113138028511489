.main_container {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;

    .header {
        position: static;
    }

    .content {
        min-height: 400px;
    }
}

@media screen and (max-width: 768px) {

    .main_container {
        overflow-x: inherit;
        overflow-y: inherit;

        .header {
            position: sticky;
            top: 0;
            z-index: 1000;
        }
    }
}
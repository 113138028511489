@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes slideInLeft {
    0% {
        transform: translateX(-100%);
    }

    100% {
        transform: translateX(0);
    }
}

@keyframes slideInRight {
    0% {
        transform: translateX(100%);
    }

    100% {
        transform: translateX(0);
    }
}

.container {
    z-index: 2;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    animation: fadeIn 0.5s ease-in-out;
}

.left_side {
    width: 70%;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    animation: slideInLeft 0.5s ease-in-out;
}

.right_side {
    z-index: 9999;
    width: 30%;
    background-color: rgba(0, 0, 0, 0.5);
    animation: slideInRight 0.5s ease-in-out;
}

.tabs {
    display: flex;
    justify-content: space-around;
    background-color: #f8f8f8;
    padding: 12px 0;
    border-bottom: 1px solid #e0e0e0;

    .tab {
        flex: 1;
        text-align: center;
        padding: 8px 0;
        font-size: 16px;
        font-weight: bold;
        color: #555;
        background: none;
        border: none;
        cursor: pointer;
        transition: color 0.3s, border-bottom 0.3s;

        &:hover {
            color: #4CAF50;
        }

        &.active {
            color: #4CAF50;
            border-bottom: 3px solid #4CAF50;
        }
    }
}

.menu,
.products {
    flex: 1;
    padding: 16px;

    ul {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
            padding: 12px 0;
            border-bottom: 1px solid #e0e0e0;
            height: 50px;
            text-transform: uppercase;
            font-weight: bold;

            a {
                text-decoration: none;
                color: #333;
                font-size: 16px;
                display: block;
                width: 100%;
                transition: color 0.3s;

                &:hover {
                    color: #4CAF50;
                }
            }
        }
    }
}
@use '../../../../variables';

.container {
    display: flex;
    width: 90%;
    justify-content: space-between;
    margin-bottom: 2%;
    height: auto;
    border-bottom: solid 3px variables.$border_color;

    .column {
        height: 80%;
        width: 45%;
        padding-bottom: 2%;

        .header {
            font-size: 18px;
            font-weight: bold;
            width: 100%;
            border-bottom: solid 3px variables.$border_color;
            padding-bottom: 2%;
        }

        .field {
            display: flex;
        }

        .field_label {
            font-weight: bold;
            display: flex;
            flex-direction: column;
        }

        .rows {
            height: 100%;
            display: flex;
            flex-direction: column;

            .first_row,
            .second_row,
            .third_row {
                margin-top: 3%;
                font-weight: 500;
            }
        }
    }
}

// Media Queries
@media (max-width: 1200px) {

    .column {
        margin-bottom: 2rem;

        .rows {

            .first_row,
            .second_row,
            .third_row {
                margin-top: 2%;
            }
        }
    }
}

@media (max-width: 768px) {
    .container {
        width: 100%;
        flex-direction: column;
        align-items: center;
        height: auto;
    }

    .column {
        width: 100%;

        .header {
            font-size: 16px;
            padding-bottom: 1%;
        }

        .rows {

            .first_row,
            .second_row,
            .third_row {
                margin-top: 1.5%;
            }
        }
    }
}

@media (max-width: 480px) {
    .container {
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        margin-bottom: 1rem;
    }

    .column {
        width: 100%;
        margin-bottom: 1rem;

        .header {
            font-size: 14px;
            padding-bottom: 0.5rem;
        }

        .rows {

            .first_row,
            .second_row,
            .third_row {
                margin-top: 1rem;
                font-weight: 400;
            }
        }
    }
}
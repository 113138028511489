@use '../../variables';

.main_container {
    background-color: variables.$basic_white_color;
    margin-left: 50%;
    width: 28%;
    transform: translateX(-50%);
    border-radius: 4;
    border-radius: 3px;
    border: 1px solid variables.$app_intense_grey_color;
    margin-top: 2%;
    margin-bottom: 8%;
    padding: 3%;

}

@media screen and (max-width: 1200px) {
    .main_container {
        width: 50%;
    }
}

@media screen and (max-width: 767px) {

    .main_container {
        width: 75%;
    }
}
@use '../../../variables';

.avaliableInContainer {
    display: flex;
    font-size: 12px;
    width: 100%;
    height: 35px;
    display: flex;
    align-items: center;

    p {
        width: 50%;
        margin: 0;
        font-weight: bold;
        font-size: 14px;
        margin: 0;
        display: flex;
        align-items: center;
        font-family: variables.$font_family;
    }
}
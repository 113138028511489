@use '../../../variables';

.container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    .menuItem {
        width: 30%;
        display: flex;
        flex-direction: column;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        margin-right: 3%;
        margin-top: 3%;
        padding: 3%;
        font-size: 13px;
        border-radius: 6px;
        border: 1px solid variables.$basic_white_color;

        .pickupPointAddress {
            font-family: variables.$font-family;

        }

        .title {
            word-wrap: break-word;
            overflow-wrap: break-word;
            text-align: center;
            white-space: normal;
            max-width: 100%;
        }

        &:hover {
            border: 1px solid variables.$app_light_green_color;
        }
    }

    @media (max-width: 1366px) {
        .menuItem {
            width: 35%;
        }
    }

    @media (max-width: 768px) {
        .menuItem {
            width: 50%;
        }
    }
}
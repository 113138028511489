@use '../../../variables';

.thumbs {
    padding-left: 0;
    padding-right: 0;
}

.thumbs .thumb {
    width: 100px !important;
    height: 100px;
    border: 1px solid variables.$app_grey_color !important;
    border-radius: 5px;
    padding: 1.5%;
    opacity: 0.5
}

.thumbs>.thumb>img {
    width: 100% !important;
    height: 100% !important;
    object-fit: cover;
}

.thumbs>.thumb:hover {
    border: 1px solid variables.$app_light_green_color !important;
    border-radius: 5px;
}

.thumbs .selected {
    border: 1px solid variables.$app_light_green_color !important;
    border-radius: 5px;
    padding: 1.5%;
    opacity: 1;
}

@media screen and (max-width: 1270px) {
    .thumbs-wrapper {
        width: 285px;
        transform: translateX(4%);
        padding-left: 0;
        padding-right: 0;
    }

    .thumbs {
        padding-left: 0;
    }

    .thumbs>.thumb {
        width: 50px !important;
        height: 50px;
        border: 1px solid variables.$app_grey_color !important;
        border-radius: 5px;
        padding: 1.5%;
        opacity: 0.5
    }

    .thumbs .selected {
        border: 1px solid variables.$app_light_green_color !important;
        border-radius: 5px;
        padding: 1.5%;
        opacity: 1;
    }
}
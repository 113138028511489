@use '../../../../variables';

.categories_container {
    display: flex;
    flex-direction: column;
    margin-left: 2px;
    overflow-y: auto;
    font-family: variables.$font-family;
    font-weight: bold;
    max-height: 100%;
    overflow-y: auto;

    .category_list {
        list-style-type: none;
        padding: 0;
        margin: 0;
        border: 1px solid variables.$basic_grey_color;

        .category_item {
            border-bottom: 1px solid variables.$basic_grey_color;
            min-height: 50px;
            height: fit-content;
            cursor: pointer;
            display: flex;
            align-items: flex-start;
            justify-content: center;
            flex-direction: column;
            position: relative;

            &:last-child {
                border-bottom: none;
            }

            .line {
                height: 50px;
                width: 4px;
                background-color: variables.$app_light_green_color;
                align-self: flex-start;
                margin-left: 0;
                position: absolute;
                top: 0;

            }

            .content {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                padding: 0 1.5rem 0 1rem;

                .category_item_content {
                    width: 100%;
                }

                p {
                    width: 200px;
                    margin: 0;
                    line-height: 1;
                    font-size: 16px;
                    font-weight: 400;
                }

                .arrow_container {
                    margin-left: 12%;
                    margin-bottom: 0.25%;


                    .arrow_expand {
                        height: 16px;
                    }
                }
            }

            .content_selected {
                display: flex;
                justify-content: space-between;
                align-items: center;
                color: variables.$app_light_green_color;
                width: 100%;

                .category_item_content {
                    padding: 0 1.5rem 0 1rem;
                }

                .line {
                    width: 4px;
                    background-color: variables.$app_light_green_color;
                    align-self: flex-start;
                    margin-left: 0;
                    position: absolute;
                    height: 50px;
                    top: 0;

                }

                p {
                    width: 200px;
                    margin: 0;
                    font-size: 16px;
                    font-weight: 400;
                }

                .arrow_container {
                    margin-left: 12%;
                    margin-bottom: 0.25%;

                    .arrow_collapse {
                        height: 16px;
                        transform: rotateZ(90deg);
                    }
                }
            }
        }
    }

    .children_categories {
        list-style-type: none;

        .child_category {

            p {
                padding: 0 3rem 0 2rem;
                margin-left: 2px;
                font-size: 16px;
                font-weight: 400;

                &:hover {
                    color: variables.$app_light_green_color;
                }
            }

        }
    }
}

.green {
    color: variables.$app_light_green_color;
}

.category_item_content {
    display: flex;
    align-items: center;
    width: 100%;
    height: 50px;
}

.category_item_image {
    margin-right: 10px;
}


@media screen and (max-width: 1300px) {
    .categories_container {
        margin: 0;
        width: 30%;

        .category_list {

            .category_item {
                display: flex;
                align-items: flex-start;
                flex-direction: column;

                .content {
                    display: flex;
                    justify-content: space-between;
                }

                .content_selected {
                    p {
                        width: 100px;
                    }

                }
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .categories_container {
        width: 100%;
        margin-bottom: 10px;

        .category_list {
            .category_item {
                .content {
                    p {
                        width: 78%;
                    }
                }

                .content_selected {
                    p {
                        width: 78%;
                    }
                }
            }
        }
    }

}
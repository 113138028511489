@use '../../../variables';

.product_description {
    max-height: 450px;
    overflow: auto;

    p {
        font-family: variables.$font-family;
        font-size: 15px;
        margin-bottom: 40px;
    }
}
@use '../../variables';

.main_container {
    font-family: variables.$font_family;

    .upper_green_area {
        min-height: 100px;
        background-color: variables.$app_light_green_color;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .categories {
            display: flex;
            align-items: center;
            width: 100%;
            max-width: 1300px;
            margin: 0 auto;
            gap: 5px;


            p {
                color: variables.$basic_white_color;
                margin-bottom: 0;
                font-size: 12px;
                font-weight: 400;

                &:hover {
                    cursor: pointer;
                    text-decoration: underline;
                }
            }

            .category_list {
                display: flex;
                gap: 5px;
            }

            .product_name {
                color: variables.$basic_black_color;
                font-family: variables.$second_font_family;

                &:hover {
                    cursor: auto;
                    text-decoration: none;
                }
            }

        }
    }

    .product_section {
        display: flex;
        gap: 3rem;
        width: 100%;
        max-width: 1300px;
        margin: 50px auto 100px auto;

        .images_section {
            width: 50%;

            .carousel {
                width: 100%;
            }

            display: flex;
            flex-direction: column;
            gap: 1rem;

            .image {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 32rem;
                padding: 70px 30px;
                border: 1px solid variables.$app_grey_color;
                border-radius: 5px;

                .arrow_back_container,
                .arrow_forward_container {
                    width: 35px;
                    height: 35px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 50%;
                    background-color: #f0f0f0;
                }

                .arrow_back_container {
                    margin-right: 2%;
                }

                .arrow_back {
                    cursor: pointer;
                    color: variables.$app_intense_grey_color;
                    margin-left: 7px;
                }

                .arrow_forward_container {
                    margin-left: 9px;
                }

                .arrow_forward {
                    cursor: pointer;
                    color: variables.$app_intense_grey_color;
                    margin-left: 2%;
                }

                img {
                    background-color: variables.$app_light_green_color;
                    max-width: 70%;
                    max-height: 90%;
                    width: auto;
                    height: auto;
                    object-fit: cover;
                }
            }
        }

        .data_section {
            font-family: variables.$second_font_family;
            display: flex;
            flex-direction: column;
            width: 50%;

            .prev_next_section {
                display: flex;
                gap: 1rem;
                margin-left: auto;
                font-size: 13px;
                font-weight: 700;

                .previous {
                    display: flex;
                    gap: 0.5rem;
                    cursor: pointer;

                    p {
                        text-transform: uppercase;
                        user-select: none;
                    }

                    .arrow_back {
                        color: variables.$app_intense_grey_color;
                    }

                    &:hover:not(.inactive) {
                        p {
                            color: variables.$app_light_green_color;
                        }

                        .arrow_back {
                            color: variables.$app_light_green_color;
                        }
                    }

                    &.inactive {
                        opacity: 0.5;
                        cursor: auto;
                    }
                }

                .next {
                    display: flex;
                    gap: 0.5rem;
                    cursor: pointer;

                    .arrow_forward {
                        color: variables.$app_light_green_color;
                    }

                    p {
                        text-transform: uppercase;
                        user-select: none;
                        color: variables.$app_light_green_color;
                    }

                    &:hover:not(.inactive) {

                        p {
                            color: variables.$app_light_green_color;
                        }

                        .arrow_forward {
                            color: variables.$app_light_green_color;
                        }
                    }

                    &.inactive {
                        opacity: 0.5;
                        cursor: auto;
                    }
                }
            }

            .product_name {
                p {
                    text-transform: uppercase;
                    font-weight: bold;
                    font-size: 20px;
                    margin-bottom: 40px;
                }
            }

            .price {

                div {
                    font-size: 20px;
                    margin-bottom: 25px;
                    color: variables.$app_light_green_color;
                    font-weight: bold;
                }

                span {
                    font-size: 12px;
                    color: variables.$basic_black_color;
                    font-weight: normal;
                }
            }

            .product_description {
                max-height: 450px;
                overflow: auto;

                p {
                    font-family: variables.$font-family;
                    font-size: 15px;
                    margin-bottom: 40px;
                }
            }

            .available {
                display: flex;
                gap: 0.8rem;
                font-size: 13px;
                font-weight: 700;

                span {
                    font-weight: 400;
                }

                .label {
                    user-select: none;
                }

                .number {
                    font-family: variables.$font-family;
                    user-select: none;
                }
            }

            .availableIn {
                gap: 0.8rem;
                font-size: 13px;
                font-weight: 700;

                .availableInParagraph {
                    color: variables.$basic_black_color;
                    font-weight: bold;
                    font-size: 14px;
                    margin-top: 0.2rem;

                    user-select: none;
                }

                .container {
                    width: 100%;
                    display: flex;
                    flex-wrap: wrap;

                    .menuItem {
                        width: 30%;
                        display: flex;
                        flex-direction: column;
                        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
                        margin-right: 3%;
                        margin-top: 3%;
                        padding: 2%;
                        font-size: 13px;

                        .pickupPointAddress {
                            font-family: variables.$font-family;
                        }
                    }

                    @media (max-width: 1366px) {
                        .menuItem {
                            width: 35%;
                        }
                    }

                    @media (max-width: 768px) {
                        .menuItem {
                            width: 50%;
                        }
                    }
                }
            }

            .counter_and_button_container {
                display: flex;
                width: 100%;
                justify-content: space-between;
                margin-top: 2%;

                .counter {
                    display: flex;
                    margin-top: 10px;
                    text-align: center;
                    display: flex;
                    align-items: center;
                    border: solid 1px variables.$app_grey_color;
                    width: 30%;
                    justify-content: space-around;
                    padding: 1%;
                    font-size: 15px;

                    input {
                        text-align: center;
                        width: 20%;
                        height: 40%;
                        border: none;
                    }


                    .minus {
                        height: 2rem;
                        width: 2rem;
                        border-radius: 50%;
                        background-color: variables.$app_grey_color;
                        cursor: pointer;
                        border: none;
                        outline: none;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: 22px;

                        &.inactive {
                            opacity: 0.3;
                            cursor: auto;
                        }

                        div {
                            color: variables.$basic_black_color;
                            margin: 0;
                            user-select: none;
                            margin-bottom: 5px;
                        }
                    }

                    .amount {
                        color: variables.$basic_black_color;
                        font-weight: bold;
                        font-size: 15px;
                        margin-top: 0.2rem;
                        user-select: none;
                    }

                    .plus {
                        height: 2rem;
                        width: 2rem;
                        border-radius: 50%;
                        background-color: variables.$basic_black_color;
                        cursor: pointer;
                        border: none;
                        outline: none;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: 22px;

                        &.inactive {
                            opacity: 0.3;
                            cursor: auto;
                        }

                        div {
                            color: variables.$basic_white_color;
                            margin: 0;
                            user-select: none;
                        }
                    }
                }

                .buy_button {
                    width: 60%;
                    height: 4rem;
                    background-color: variables.$app_light_green_color;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 1rem;
                    margin-top: 0.75rem;
                    border-radius: 3px;
                    cursor: pointer;
                    user-select: none;
                    border: none;
                    outline: none;

                    &.inactive {
                        opacity: 0.6;
                        cursor: auto;
                    }

                    p {
                        text-transform: uppercase;
                        color: variables.$basic_white_color;
                        margin-top: 1rem;
                    }

                    img {
                        width: 1.7rem;
                        height: 1.7rem;
                        filter: variables.$convert_to_white_color;
                    }
                }
            }

            .product_sale_unit_name {
                font-family: variables.$font-family;
                font-size: 15px;
            }
        }
    }
}

@media screen and (max-width: 1300px) {
    .main_container {
        width: 100%;

        .upper_green_area {
            width: 100%;
            min-height: 50px;
            font-size: 15px;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .categories {
                display: flex;
                flex-wrap: wrap;
                gap: 0.4rem;
                padding: 0 20px;

                p {
                    width: max-content;
                    font-size: 12px;
                }
            }
        }

        .product_section {
            display: flex;
            flex-direction: column;
            align-content: center;
            padding: 0 20px;
            margin-bottom: 6%;

            .images_section {

                .carousel {
                    width: 340px;
                }

                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 5px;
                width: 100%;
                box-sizing: border-box;

                .image {
                    display: flex;
                    align-items: center;
                    width: 340px;
                    height: 300px;
                    padding-top: 1.5rem;
                    padding-left: 0.5rem;
                    padding-right: 0.5rem;
                    padding-bottom: 1.5rem;
                    border: 1px solid variables.$app_grey_color;

                    img {
                        max-width: 80%;
                        min-width: 80%;
                        align-self: center;
                    }

                    .arrow_back_container {
                        width: 25px;
                        height: 25px;
                        margin-right: 2%;

                        .arrow_back {
                            cursor: pointer;
                            color: variables.$app_intense_grey_color;
                            margin-left: 7px;
                        }
                    }

                    .arrow_forward_container {
                        width: 25px;
                        height: 25px;
                        margin-left: 9px;

                        .arrow_forward {
                            cursor: pointer;
                            color: variables.$app_intense_grey_color;
                            margin-left: 2%;
                        }
                    }
                }

            }

            .data_section {
                width: 98%;
            }
        }
    }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.green_link {
    color: variables.$app_light_green_color;
    font-size: 14px;
    cursor: pointer;
}
@use '../../../../variables';

.product_carousel_container {
    width: 100vw;
    height: 400px;
    position: relative;
    margin-top: -10px;

    .carousel_wrapper {
        width: 100%;
        height: 400px;

        .image_item {

            .link_wrapper {
                display: flex;
                justify-content: center;
                align-items: center;

                img {
                    width: 75%;
                    height: 400px;
                    object-fit: contain;
                }

                .content_wrapper {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    max-width: 1100px;
                    width: 100%;
                    margin: 0 auto;
                    display: flex;

                    .carousel_item_content {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;

                        p {
                            text-transform: uppercase;
                            font-weight: 600;
                            margin: 0;

                            &.content_top {
                                color: variables.$app_dark_green_color;
                                font-size: 14px;
                                line-height: 14px;
                                margin-bottom: 20px;
                            }

                            &.content_middle {
                                color: variables.$app_green_color;
                                font-size: 43px;
                                line-height: 43px;
                                margin-bottom: 20px;
                            }

                            &.content_bottom {
                                color: variables.$carousel_light_green_color;
                                font-size: 43px;
                                line-height: 43px;
                            }

                            &.support_content_top {
                                color: variables.$app_dark_green_color;
                                font-size: 14px;
                                line-height: 14px;
                                margin-bottom: 20px;
                            }

                            &.support_content_middle {
                                color: variables.$basic_white_color;
                                font-size: 43px;
                                line-height: 43px;
                                margin-bottom: 20px;
                            }

                            &.support_content_bottom {
                                color: variables.$basic_white_color;
                                font-size: 43px;
                                line-height: 43px;
                            }
                        }

                        button {
                            width: 150px;
                            text-align: center;
                            text-transform: uppercase;
                            height: 48px;
                            line-height: 48px;
                            font-size: 12px;
                            font-weight: 700;
                            color: #fff;
                            border: none;
                            border-radius: 5px;
                            background-color: variables.$carousel_light_green_color;
                            margin-top: 40px;

                            &.support_buy_now_button {
                                background-color: variables.$app_dark_green_color;
                            }
                        }
                    }

                }
            }
        }
    }


}

@media screen and (max-width: 1300px) {
    .product_carousel_container {
        width: 100%;
        height: 400px;
        object-fit: cover;

        .carousel_wrapper {
            width: 100%;
            height: 400px;

            .carousel_item_content {
                padding: 0 20px;
            }

            .image_item {
                .link_wrapper {
                    img {
                        width: 100%;
                        height: 400px;
                    }

                    .content_wrapper {
                        .banner {
                            margin-left: 0;
                            margin-right: 20px;
                        }
                    }
                }
            }
        }
    }

}

@media screen and (max-width: 767px) {
    .product_carousel_container {
        height: 250px;

        .carousel_wrapper {
            height: 250px;

            .image_item {
                height: 250px;

                .link_wrapper {
                    width: 100%;
                    height: 250px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: #fff;
                    position: relative;

                    img {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        object-fit: fill;
                    }
                }
            }
        }

        .carousel_item_content {

            .content_middle,
            .content_bottom {
                font-size: 35px !important;
                line-height: 35px !important;
            }

            button {
                width: 130px;
                height: 40px;
                line-height: 40px;
            }
        }
    }
}
@use '../../../variables';

.main_container {
    display: flex;
    flex-direction: column;
    border-top: 1px solid variables.$app_grey_color;
    width: 100%;
    max-width: 1300px;
    margin: 80px auto 0 auto;

    .header_container {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 80px;
        margin-bottom: 30px;
    }

    .header {
        display: block;
        justify-content: center;
        text-align: center;
        margin-bottom: 0;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 23px;
        font-family: variables.$second_font_family;

    }

    .name {
        font-size: 28px;
        font-family: variables.$font-family;
        letter-spacing: 1px;
    }
}

.cards_container {
    align-items: center;
    display: flex;
    justify-content: flex;
    gap: 2rem;
}

.arrow_back_container {
    min-width: 45px;
    min-height: 45px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.arrow_forward_container {
    min-width: 45px;
    min-height: 45px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.my_swiper {
    justify-content: center;
    display: flex;
    align-items: center;
}

.border_top_none {
    border-top: none;
}




@media screen and (max-width: 1000px) {
    .main_container {
        min-height: 60vh;
        width: 100%;

        .cards_container {
            display: flex;
            justify-content: center;
            margin-left: 1%;
            gap: 2%;
        }

    }
}

@media screen and (max-width: 576px) {

    .arrow_forward_container,
    .arrow_back_container {
        display: none;
    }
}
@use '../../variables';

.card_container {
    width: 300px;
    height: 595px;
    background-color: variables.$basic_white_color;
    border: 1px solid variables.$app_intense_grey_color;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 4px;
    margin-top: 40px;

    &:hover {
        border: 1px solid variables.$app_light_green_color;
    }

    .image_icons_container {
        width: 100%;
        height: 200px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        margin-top: 20px;

        .product_image,
        .image_placeholder {
            height: 160px;
            width: 240px;
            border-radius: 4px;
            object-fit: contain;
        }

        .image_placeholder {
            background-color: variables.$app_light_green_color;
        }
    }

    .product_name {
        font-size: 15px;
        text-align: center;
        font-weight: 500;
        width: 240px;
        min-height: 45px;

        &:hover {
            color: variables.$app_light_green_color;
            cursor: pointer;
        }
    }

    .producer {
        font-size: 15px;
        margin-bottom: 0;
        width: 95%;
        text-align: center;
        display: flex;
        justify-content: center;
        margin-top: 10px;

        &:hover {
            color: variables.$app_light_green_color;
            cursor: pointer;
        }

        .producer_strong {
            font-weight: bold;
            text-align: right;
        }

        .producer_name {
            text-align: left;
            margin-left: 5px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: normal;
        }
    }

    .price_and_amount_container {
        display: flex;
        justify-content: space-between;
        width: 240px;
        margin: 15px auto 20px auto;

        .amount_wrapper {
            width: 150px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            input {
                text-align: center;
                border: none;
                width: 40%;
                border-radius: 6px;
                height: 25px;
                border: solid 1px variables.$app_grey_color;
            }

            div {
                text-align: center;
                font-size: 12px;
            }
        }

        .minus_button {
            color: variables.$basic_black_color;
            height: 25px;
            width: 25px;
            border-radius: 50%;
            background-color: variables.$app_grey_color;
            cursor: pointer;
            outline: none;
            border: none;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;

            &.inactive {
                opacity: 0.3;
                cursor: auto;
            }
        }

        p {
            color: variables.$basic_black_color;
            font-weight: bold;
            margin-top: 0;
            margin-left: 1px;
            user-select: none;
            margin-bottom: 4px;

            &.quantity {
                font-size: 10px;
                margin-left: 15px;
            }
        }

        .plus_button {
            color: variables.$basic_white_color;
            height: 25px;
            width: 25px;
            background-color: variables.$basic_black_color;
            border-radius: 50%;
            cursor: pointer;
            outline: none;
            border: none;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;

            &.inactive {
                opacity: 0.3;
                cursor: auto;
            }

            p {
                margin: 0 1px 4px 0;
                color: variables.$basic_white_color;
                user-select: none;
            }
        }

        .price {
            align-self: center;
            margin-left: auto;
            width: auto;

            p {
                color: variables.$app_light_green_color;
                font-size: 14px;
                margin: 0;
            }
        }
    }

    .add_to_cart {
        min-height: 40px;
        width: 240px;
        background-color: variables.$product_card_add_to_card_color;
        border-radius: 8px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        outline: none;

        &.inactive {
            opacity: 0.6;
            cursor: auto;
        }

        img{
            height: 20px;
            width: 20px;
            margin-right: 10px;
            filter: variables.$convert_to_white_color;
        }

        p {
            color: variables.$basic_white_color;
            text-transform: uppercase;
            font-weight: bold;
            font-size: 12px;
            user-select: none;
            margin: 0;
        }
    }

    .add_to_cart_confirmation {
        min-height: 40px;
        width: 240px;
        background-color: variables.$product_card_add_to_card_color;
        border-radius: 8px;
        cursor: pointer;
        display: flex;
        justify-content: space-around;
        align-items: center;

        .check_icon {
            color: variables.$basic_white_color;
        }

        span {
            color: variables.$basic_white_color;
            font-size: 12px;
            user-select: none;
            margin: 0;
        }
    }

    .buy_button {
        background-color: variables.$app_light_green_color;
        width: 240px;
        min-height: 55px;
        margin-top: 5%;
        border-radius: 3px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        outline: none;
        border: none;

        &.inactive {
            opacity: 0.6;
            cursor: auto;
        }

        img {
            height: 20px;
            width: 20px;
            margin-right: 10px;
            filter: variables.$convert_to_white_color;
        }

        p {
            text-transform: uppercase;
            font-weight: bold;
            font-size: 12px;
            color: variables.$basic_white_color;
            margin: 0;
        }
    }

    .available {
        margin-left: 45%;
        margin-top: 4%;
        display: flex;
        gap: 5px;

        .number {
            font-weight: bold;
        }
    }

    .pickup_points_list {
        width: 240px;
        margin-top: 5%;

        .availableIn {
            color: variables.$basic_black_color;
            font-size: 14px;
            margin: 0;
            width: 100%;
            font-weight: 500;
        }

        .availableInContainer {
            width: 100%;
        }
    }

    .availability {
        width: 240px;
        margin-top: 15px;
        margin-bottom: 15px;

        p {
            font-size: 12px;
            margin: 0;
            color: variables.$basic_grey_color;
            text-align: right;

            span {
                font-weight: 700;
                color: variables.$basic_black_color;
            }
        }
    }
}

@media screen and (max-width: 1000px) {
    .card_container {
        width: 330px;
        margin-top: 0;

        .price_and_amount_container {
            margin-left: 10%;

            .price {
                margin-left: 65px;
            }
        }
    }
}

@media screen and (max-width: 767px) {

    .card_container {
        margin-top: 40px;

        .price_and_amount_container {
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-left: 0;
            width: 100%;


            .price {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-left: 0;
                margin-top: 10px;
                padding: none;

                p {
                    font-size: 18px;
                }
            }
        }
    }
}
@use '../../../../variables';

.user_details_container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 5%;
    margin-left: 2%;
    width: 100%;
    font-family: variables.$font-family;

    .title {
        font-size: larger;
        font-size: 28px;
        font-weight: bold;
        text-transform: uppercase;
        color: variables.$app_green_color;

    }

    .user_data_container {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-top: 3%;
        background-color: variables.$basic_white_color;

        .double_section {
            display: flex;
            width: 100%;
            flex-wrap: wrap;
            border-bottom: 1px solid variables.$basic_grey_color;
            gap: 40%;

            .section {
                border: none;
                flex: 1;
            }
        }

        .section {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 10px;
            border-bottom: 1px solid variables.$basic_grey_color;
            margin-bottom: 20px;

            .section_subtitle {
                font-size: large;
                font-weight: bold;
                color: variables.$app_green_color;
                text-transform: uppercase;
            }

            .section_data {

                .row {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 5px;
                    height: fit-content;

                    .data_name {
                        font-size: medium;
                        font-weight: bold;
                        color: variables.$app_light_green_color;
                        font-family: variables.$second_font_family;
                    }

                    .data_value {
                        font-size: medium;
                        font-weight: bold;
                    }
                }
            }
        }
    }
}

.section_title {
    font-size: larger;
    font-size: 24px;
    font-weight: bold;
    text-transform: uppercase;
    color: variables.$app_green_color;
}

.table_container {
    width: 100%;

    table {
        width: 100%;

        tr {
            border-bottom: 1px solid variables.$basic_grey_color;
        }

        thead {
            text-transform: uppercase;

            th {
                font-weight: 700;
                font-size: 12px;
                color: #000;
            }

            .order {
                width: 20%;
            }

            .date {
                width: 20%;
            }

            .address {
                width: 20%;
            }

            .summary {
                width: 20%;
            }

            .status {
                width: 20%;
            }
        }

        tbody {

            td {
                padding-top: 30px;
                padding-bottom: 30px;
                font-size: 12px;

            }

            .summary_column, .status_column {

                font-weight: bold;
                color: variables.$app_light_green_color;
            }
        }
    }
}



@media screen and (max-width: 1510px) {
    .user_details_container {
        width: 70%;
    }
}

@media screen and (max-width: 1370px) {

    .user_details_container {

        width: 100%;

        .title {
            width: max-content;
            margin-left: 0.5rem;
        }

        .user_data_container {
            display: flex;
            flex-direction: column;
            width: 90%;
            margin-left: 5%;
            margin-right: 5%;
            gap: 10px;
        }
    }
}
.container {
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
}

.select {
    height: 30px;
    width: 100%;
    min-width: 150px;
    font-size: 13px;
    box-sizing: border-box;
    border: none;
    outline: none;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.menuItem {
    font-size: 14px;
}

fieldset {
    border: none !important;
    outline: none !important;
}
@use '../../../../variables';

.main_container {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    margin-left: 30px;

    .cards_container {
        display: flex;
        flex-wrap: wrap;
        gap: 25px;
        margin-top: -30px;
    }

    .paginator_container {
        margin-top: 3%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

@media screen and (max-width: 1000px) {

    .main_container {
        min-height: 60vh;
        align-items: center;

        .navigation {
            margin-bottom: 50px;
            width: 100%;
        }

        .cards_container {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
        }

        .paginator_container {
            justify-content: flex-start;
        }
    }


}


@media screen and (max-width: 767px) {
    .main_container {
        margin-left: 0;

        .navigation {
            margin: 0;
        }
    }
}
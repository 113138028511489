.carousel {
    .carousel-control-prev {
        display: none;
        width: 40px;
        margin-left: 10%;
        height: 40px;
        top: 45%;
    }

    .carousel-control-next {
        display: none;
        width: 40px;
        height: 40px;
        top: 45%;
        margin-right: 10%;
    }

    .carousel-indicators {
        align-items: center;
    }

    .carousel-indicators [data-bs-target] {
        width: 6px;
        height: 6px;
        border-radius: 50%;
        border: none;
        background-color: #fff;
        opacity: 1;
    }

    .carousel-indicators .active {
        border: 2px solid #fff;
        background-color: transparent;
        width: 8px;
        height: 8px;
    }
}
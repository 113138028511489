@use '../../../variables';

.main_container {
    width: 28px;
    height: 28px;
    border: 1px solid variables.$app_intense_grey_color;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    .arrow {
        color: variables.$app_intense_grey_color;
    }

    &.clickable {
        border: 1px solid variables.$app_light_green_color;
        cursor: pointer;

        .arrow {
            color: variables.$app_light_green_color;
        }

        &:hover {
            background-color: variables.$app_light_green_color;

            .arrow {
                color: variables.$basic_white_color;
            }
        }
    }
}
@use '../../variables';


.main_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    .cards_container {
        display: flex;
        gap: 25px;
        flex-wrap: wrap;
        max-width: 1300px;
    }

    .paginator_container {
        margin-top: 5%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

@media screen and (max-width: 767px) {
    .main_container {
        
        .cards_container {
            padding-left: 0;
            justify-content: center;

            .card_container {
                width: 300px;
            }
        }
    }
}
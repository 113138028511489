@use '../../variables';

.paginator_container {
    display: flex;
    align-items: center;

    select {
        option {
            background-color: variables.$app_light_green_color;
        }
    }
}

.items_per_page_select {
    margin: 10px;
}

.items_per_page_label {
    margin: 0;
}

.pagination {
    display: inline-block;
    padding-left: 0;
    margin: 20px 0;
    border-radius: 4px;
}

.pagination>li {
    display: inline;
}

.pagination>li>a,
.pagination>li>span {
    position: relative;
    float: left;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: variables.$app_light_green_color;
    text-decoration: none;
    background-color: #fff;
    margin: 0 5px;
    border-radius: 50%;
}

.pagination>li:first-child>a,
.pagination>li:first-child>span {
    margin-left: 0;
    font-size: 25px;
    color: #000;
}

.pagination>li:last-child>a,
.pagination>li:last-child>span {
    font-size: 25px;
    color: #000;
}

.pagination>li:first-child>a:hover,
.pagination>li:first-child>span:hover,
.pagination>li:last-child>a:hover,
.pagination>li:last-child>span:hover,
.pagination>li:first-child>a:focus,
.pagination>li:first-child>span:focus,
.pagination>li:last-child>a:focus,
.pagination>li:last-child>span:focus {
    background-color: transparent !important;
    border-color: transparent !important;
    color: variables.$app_light_green_color !important;
}

.pagination>li>a:hover,
.pagination>li>span:hover,
.pagination>li>a:focus,
.pagination>li>span:focus {
    z-index: 2;
    color: #fff;
    background-color: variables.$app_light_green_color;
    border-color: variables.$app_light_green_color;
}

.pagination>.active>a,
.pagination>.active>a:focus,
.pagination>.active>a:hover,
.pagination>.active>span,
.pagination>.active>span:focus,
.pagination>.active>span:hover {
    z-index: 3;
    color: #fff !important;
    background-color: variables.$app_light_green_color;
    border-color: variables.$app_light_green_color;
    cursor: default;
}
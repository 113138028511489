@use '../../variables';

.main_container {
    width: 100%;
    padding: 5%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    font-family: variables.$font-family;

    .table_container {
        width: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        overflow-y: hidden;

        .table_wrapper {
            width: 80%;
            display: flex;
            justify-content: center;
            max-height: 55vh;
            min-height: 40vh;
            overflow-x: hidden;
            overflow-y: auto;

            .table {
                width: 100%;
                font-size: 110%;
                font-family: variables.$font-family;
                margin-bottom: auto;
                text-align: center;

                thead {
                    position: sticky;
                    top: 0;
                    text-align: left;
                    background-color: variables.$basic_white_color;

                    tr {
                        box-shadow: 0px 5px 0px -1px variables.$app_light_green_color;
                        line-height: 30px;


                        th {
                            padding: 1%;
                        }
                    }

                }

                tbody {
                    tr {
                        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
                        line-height: 30px;

                        &:hover {
                            color: variables.$basic_white_color;
                            background-color: variables.$app_green_color;
                        }

                        td {
                            padding: 1%;
                        }
                    }
                }
            }
        }

        .paginator_container {
            margin-top: 3%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}

@media screen and (max-width: 813px) {
    .main_container {
        padding: 1%;

        .table_container {
            .table_wrapper {
                width: 100%;
            }
        }
    }
}

@media screen and (max-width: 626px) {
    .main_container {
        .table_container {
            .table_wrapper {
                width: 100%;

                .table {
                    width: 100%;
                    font-size: 3vw;
                }
            }
        }
    }
}
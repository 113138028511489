@use '../../variables';

.container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .menuItem {
        width: 50%;
        display: flex;
        flex-direction: column;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        margin-top: 3%;
        word-wrap: break-word;

        .pickupPointAddress {
            font-family: variables.$font-family;
        }

        .title,
        p {
            font-size: 9px;
            font-weight: bold;
            word-wrap: break-word;
            overflow-wrap: break-word;
            text-align: center;
            white-space: normal;
            max-width: 100%;
        }
    }

    @media (max-width: 1366px) {
        .menuItem {
            width: 35%;
        }
    }

    @media (max-width: 768px) {
        .menuItem {
            width: 50%;
        }
    }
}
@use '../../../../../variables';

.image_container {
    width: 80px;
    height: 80px;
    cursor: pointer;
    position: relative;

    a {
        display: block;
        width: 100%;
        height: 100%;

        .producer_image {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            object-fit: cover;
        }

        .grey_circle {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            background-color: variables.$app_intense_grey_color;
        }
    }
}
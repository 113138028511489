@use '../../../variables';

.form {
    display: flex;

    input:-webkit-autofill {
        -webkit-background-clip: text;
    }

    input,
    select {
        border: 1px solid variables.$app_intense_grey_color;
        border-radius: 3px;
    }

    input[type=radio] {
        accent-color: variables.$app_light_green_color;
        width: 15px;
        height: 15px;
    }

    .left_section {

        .basic_data {

            .section_title {
                margin-left: 1vh;
                font-size: 25px;
                text-transform: uppercase;
                color: variables.$app_light_green_color;
            }
        }

        .contact_data {
            .section_title {
                margin-top: 8%;
                margin-left: 1.5%;
                font-size: 25px;
                text-transform: uppercase;
                color: variables.$app_light_green_color;
            }
        }

        .address_data {
            margin-top: 2%;

            .section_title {
                margin-left: 1vh;
                font-size: 25px;
                text-transform: uppercase;
                color: variables.$app_light_green_color;
            }
        }

        .button_container {
            display: flex;
            justify-content: flex-end;
            margin-left: 1.5%;
            margin-top: 8%;

            button {
                height: 3rem;
                width: 10rem;
                border: none;
                outline: none;
                font-family: variables.$second_font_family;
                text-transform: uppercase;
                color: variables.$basic_white_color;
                border-radius: 3px;
                background-color: variables.$app_light_green_color;
            }
        }
    }

    .form_row {
        display: flex;
        margin: 1vh;
        width: 50;

        .field_name {
            margin-bottom: 1%;
            font-size: 13px;
            font-family: variables.$second_font_family;
        }

        .normal_field {
            width: 20vw;
            padding: 10px;
        }

        .error {
            border: 1px solid red;
        }

        .country_dropdown {
            width: 100%;
            padding: 10px;

            option {
                background-color: variables.$app_light_green_color;
                color: variables.$basic_white_color;
            }
        }

        .address_type_radio_group {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            margin-top: 1%;
            gap: 3%;

            .control {
                min-width: 80px;
                display: flex;
                gap: 6%;

                label {
                    transform: translateY(-20%);
                }
            }
        }

        .contact_title_radio_group {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            margin-top: 1%;

            .control {
                min-width: 80px;
                display: flex;
                gap: 6%;

                label {
                    transform: translateY(-20%);
                }
            }
        }

        .streetWrapper,
        .countriesWrapper {
            width: 100%;
        }

    }

    .double_field_row {
        display: flex;
        width: 28vw;
        margin: 1vh;
        align-items: center;

        .form_row {
            margin: 0;
            width: 14vw;

            .first_field_name {
                width: 7vw;
                margin-bottom: 1%;
                font-size: 13px;
                font-family: variables.$second_font_family;
            }

            .first_double_row_input {
                width: 40%;
                padding: 4%;
            }

            .second_field_name {
                width: 7vw;
                margin-bottom: 1%;
                font-size: 13px;
                font-family: variables.$second_font_family;
            }

            .second_double_row_input {
                width: 42%;
                padding: 4%;
            }

            .flat_field_name {
                width: 7vw;
                margin-bottom: 1%;
                font-size: 13px;
                font-family: variables.$second_font_family;
            }

            .equal_double_row_input {
                width: 42%;
                padding: 4%;
            }
        }
    }

    .errorText {
        color: variables.$basic_red_color;
    }
}

.ml15 {
    margin-left: 15px;
}

.error {
    border: 1px solid red;
}

.link {
    color: variables.$app_light_green_color;
}

@media screen and (max-width: 767px) {
    .form {
        .form_row {
            .normal_field {
                width: 40vw;
            }
        }

        .left_section {
            .button_container {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-left: 0;

                button {
                    width: 95%;
                }
            }
        }
    }

}
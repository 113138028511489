@use "../../../variables";

.container {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    justify-content: center;
    align-items: center;
    z-index: 999;

    .modal {

        background-color: white;
        padding: 2%;
        border-radius: 8px;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        max-width: 50%;
        height: 60%;
        overflow-y: auto;
        border-radius: 12px;


        h2 {
            font-size: 32px;
        }

        p {
            font-size: 18px;
            overflow: auto;
        }

        .btn_container {
            width: 100%;
            display: flex;
            justify-content: flex-end;

            .close_button {
                margin-bottom: 10px;
                color: variables.$basic_white_color;
                background-color: variables.$app_light_green_color;
                border: none;
                border-radius: 5px;
                cursor: pointer;
                height: 30px;
                width: 30px;
            }
        }

        &::-webkit-scrollbar {
            width: 12px;
            border-radius: 12px;
        }

        &::-webkit-scrollbar-track {
            background: #f1f1f1;
            border-radius: 12px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: variables.$app_light_green_color;
            border-radius: 12px;
        }

    }


}


.open {
    display: flex;
}
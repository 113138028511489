@use '../../variables';

.dropdownDisplay {
    position: relative;
    width: 240px;
    font-family: variables.$second_font_family;
    font-size: 14px;
    color: #333;
    background-color: white;
}

.dropdownHeader {
    height: 35px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 16px;
    border: 1px solid #d1d5db;
    border-radius: 6px;
    background-color: white;
    cursor: pointer;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;

    &:hover {
        background-color: #f3f4f6;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }
}

.dropdownArrow {
    font-size: 12px;
    color: #6b7280;
    transition: transform 0.3s ease;
}

.dropdownOptions {
    position: absolute;
    top: calc(100% + 4px);
    left: 0;
    right: 0;
    border: 1px solid #e5e7eb;
    border-radius: 6px;
    background-color: white;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    max-height: 250px;
    overflow-y: scroll;
    z-index: 1000;
}

.dropdownOption {
    padding: 12px 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: #f3f4f6;
    }
}

.optionLabel {
    font-weight: bold;
    color: #111;
}

.optionAddress {
    display: block;
    font-size: 12px;
    color: #6b7280;
    margin-top: 4px;
}

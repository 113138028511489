.categoryImage {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;

    .image {
        width: 25px;
        height: 25px;
        object-fit: contain;
    }

    .svgContainer {
        width: 25px;
        height: 25px;
        display: flex;
        justify-content: center;

        svg {
            max-width: 100%;
            max-height: 100%;
        }
    }
}

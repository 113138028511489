@use '../my-account-info/MyAccountInfo.module.scss';
@use '../../../../variables';

.no_orders_warning {
    width: 100%;
    height: 120px;
    background-color: variables.$app_green_color;
    border-radius: 10px;

    .warning_content {
        display: flex;
        color: variables.$basic_white_color;
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 10px;

        .warning_icon {
            margin-right: 10px;
        }

    }

    .go_shopping_button {
        background-color: variables.$app_grey_color;
        width: 240px;
        height: 50px;
        border-radius: 3px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: variables.$font-family;
        cursor: pointer;
        margin-top: 1%;
        margin-left: 10px;
        text-transform: uppercase;
        font-weight: bold;
        color: variables.$basic_black_color;
        font-size: 12px;
        border: none;
    }
}

.form {
    display: flex;

    input:-webkit-autofill {
        -webkit-background-clip: text;
    }

    input,
    select {
        border: 2px solid variables.$app_intense_grey_color;
        border-radius: 3px;

    }

    input[type=radio] {
        accent-color: variables.$app_light_green_color;
        width: 15px;
        height: 15px;
    }

    .left_section {
        .address_data {
            .section_title {
                margin-left: 1.5%;
                font-size: 25px;
                text-transform: uppercase;
                color: variables.$app_light_green_color;
            }
        }
    }

    .form_row {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin: 1vh;

        .error {
            border: 1px solid red;
        }

        .field_name {
            margin-bottom: 1%;
            font-size: 13px;
            font-family: variables.$second_font_family;
        }

        .normal_field {
            width: 100%;
            padding: 2%;
        }

        .country_dropdown {
            width: 100%;
            padding: 2%;

            option {
                background-color: variables.$app_light_green_color;
                color: variables.$basic_white_color;

                &:hover {}
            }
        }

        .address_type_radio_group {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            margin-top: 1%;
            gap: 3%;

            .control {
                min-width: 80px;
                display: flex;
                gap: 6%;

                input {}

                label {
                    transform: translateY(-20%);
                }
            }
        }

        .contact_title_radio_group {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            margin-top: 1%;

            .control {
                min-width: 80px;
                display: flex;
                gap: 6%;

                input {}

                label {
                    transform: translateY(-20%);
                }
            }
        }


    }

    .double_field_row {
        display: flex;
        width: 100%;
        margin: 1vh;
        align-items: center;
        gap: 5%;

        .form_row {
            margin: 0;
            width: 100%;

            .first_field_name {
                width: 100%;
                margin-bottom: 1%;
                font-size: 13px;
                font-family: variables.$second_font_family;
            }

            .first_double_row_input {
                width: 100%;
                padding: 4%;
            }

            .second_field_name {
                width: 100%;
                margin-bottom: 1%;
                font-size: 13px;
                font-family: variables.$second_font_family;
            }

            .second_double_row_input {
                width: 100%;
                padding: 4%;
            }

            .flat_field_name {
                width: 100%;
                margin-bottom: 1%;
                font-size: 13px;
                font-family: variables.$second_font_family;
            }

            .equal_double_row_input {
                width: 100%;
                padding: 4%;
            }
        }
    }

    .save_button {
        border-radius: 4px;
        background-color: variables.$app_light_green_color;
        width: 150px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        font-weight: bold;
        font-size: 12px;
        text-transform: uppercase;
        color: variables.$basic_white_color;
        border: none;
    }

    .errorText {
        color: variables.$basic_red_color;
        margin-left: 25px
    }


}

.add_additional_address_btn,
.action_button {
    background-color: variables.$app_light_green_color;
    height: 50px;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: variables.$font-family;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: bold;
    color: variables.$basic_white_color;
    font-size: 12px;
    border: none;
    margin-top: 1%;
}

.action_button {
    width: 50px;
    margin-right: 10px;
}

.buttons_container {
    display: flex;
}
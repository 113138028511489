@use '../../variables';

.main_container {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    .producer_container {
        width: 70%;
        display: flex;
        flex-direction: row;
        padding: 10px;
        margin-bottom: 1%;
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-radius: 10px;

        .producer_picture {
            width: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 2%;
        }

        .producer_description {
            width: 50%;
        }
    }

    .section_wrapper {
        width: 70%;
        gap: 10%;
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-radius: 10px;
        display: flex;
        padding: 10px;
        margin-bottom: 1%;

        .certificate_item {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-direction: column;

            .certificate_details {
                display: flex;
                flex-direction: row;

                img {
                    height: 200px;
                    width: 200px;
                    align-self: center;
                    object-fit: contain;
                }

                .certificate_description {
                    display: flex;
                    flex-direction: column;
                    margin-left: 2%;

                    .certificate_name {
                        align-self: center;
                        font-family: variables.$font_family;
                        margin: 0;
                        white-space: nowrap;
                        font-size: large;
                    }

                    .certificate_by_name {
                        align-self: center;
                        font-family: variables.$font_family;
                        margin: 0;
                        white-space: nowrap;
                        font-size: large;
                        color: variables.$app_light_green_color;
                        font-family: variables.$second_font_family;
                    }
                }
            }
        }
    }

    .other_products {
        width: 100%;
        padding: 10px;

    }
}

.name {
    font-size: 28px;
    font-family: variables.$font-family;
    letter-spacing: 1px;
}
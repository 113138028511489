.swiper-wrapper {
    margin-bottom: 5em !important;
    width: 100%;
}

.swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
}

.swiper-pagination-bullet {
    color: #010000;
    background: #000000;
    width: 1em;
    height: 1em;
    opacity: 1;
    border: 4px solid rgb(255, 255, 255);
}

.swiper-pagination-bullet-active {
    background: #ffffff;
    border: 2px solid black;
    width: 1em;
    height: 1em;
}


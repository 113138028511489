@use '../../../variables';

.image_container {
    width: 70px;
    height: 70px;
    border: 1px solid variables.$basic_grey_color;
    border-radius: 3px;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

@media screen and (max-width: 600px) {
    .image_container {
        width: 50px;
        height: 50px;
    }
}

.wrapper {
    display: flex;
    justify-content: center;
    width: 100%;

    .main_container {
        display: grid;
        grid-template-columns: 300px 1000px;
        max-width: 1300px;
        width: 100%;
        margin: 0 auto;
    }
}
@use '../../variables';

.card_container {
    width: 300px;
    height: auto;
    background-color: variables.$basic_white_color;
    border: 1px solid variables.$app_intense_grey_color;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 4px;

    &:hover {
        border: 1px solid variables.$app_light_green_color;
    }

    .producer_name_container {
        height: 20px;
        margin: 30px;
        text-align: center;
    }

    .image_icons_container {
        width: 100%;
        height: 110px;
        margin-top: 40px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        .producer_image {
            width: 160px;
            height: 160px;
            width: auto;
            height: auto;
            border-radius: 4px;
            background-color: transparent;
        }

        .image_placeholder {
            width: 200px;
            height: 110px;
            object-fit: cover;
            border-radius: 4px;
            background-color: variables.$app_light_green_color;
        }
    }

    h6 {
        margin-top: 20px;
        margin-bottom: 0;

        &:hover {
            color: variables.$app_light_green_color;
            cursor: pointer;
        }
    }

    .more_button {
        background-color: variables.$app_light_green_color;
        width: 240px;
        height: 55px;
        margin-top: 5%;
        border-radius: 3px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        outline: none;
        border: none;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 12px;
        color: variables.$basic_white_color;
        margin-bottom: 20px;
    }

}

@media screen and (max-width: 1000px) {
    .card_container {
        width: 330px;
        margin-top: 10%;

        .price_and_amount_container {
            margin-left: 10%;

            .price {
                margin-left: 65px;
            }
        }
    }
}
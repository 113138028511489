@use '../../variables';

.przelewy24_select_wrapper {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    justify-items: center;
    flex-direction: column;

    h1 {
        align-self: center;
        margin-bottom: 30px;
        font-family: variables.$font-family;
        color: variables.$basic_black_color;
    }

    .loading_spinner {
        display: flex;
        align-self: center;
        justify-self: center;
    }

    .grid_container {
        display: grid;
        grid-template-columns: repeat(7, 8rem);
        gap: 10px;
        justify-content: center;

        .grid_item {
            height: 100px;
            align-items: center;
            display: flex;
            justify-content: center;
            border: solid 1px variables.$app_light_green_color;
            border-radius: 10px;
            cursor: pointer;

            img {
                height: 50%;
                max-width: 100%;
            }

            &.selected {
                background-color: variables.$basic_grey_color;
            }
        }
    }

    .button {
        justify-self: center;
        align-self: center;
        margin-top: 20px;
        margin-bottom: 20px;
        background-color: variables.$basic_grey_color;
        text-transform: uppercase;
        border-radius: 3px;
        color: variables.$basic_white_color;
        border: none;
        cursor: pointer;
        font-weight: bold;
        width: 20%;
        height: 100%;
        padding: 10px;
        white-space: nowrap;
        font-size: 12px;
        text-align: center;

        &.selected {
            background-color: variables.$app_light_green_color;
        }

        &.disabled {
            background-color: variables.$basic_grey_color;
            pointer-events: none;
        }
    }

    @media (max-width: 1200px) {
        .grid_container {
            grid-template-columns: repeat(5, 8rem);
        }

        .button {
            width: 30%;
        }
    }

    @media (max-width: 992px) {
        .grid_container {
            grid-template-columns: repeat(4, 20%);
        }

        .button {
            width: 40%;
        }
    }

    @media (max-width: 768px) {
        .grid_container {
            grid-template-columns: repeat(3, 30%);

        }


        .button {
            width: 50%;
        }
    }

    @media (max-width: 576px) {
        .grid_container {
            grid-template-columns: repeat(2, 45%);
            gap: 8px;
        }


        .button {
            width: 70%;
        }
    }

    @media (max-width: 400px) {
        .grid_container {
            grid-template-columns: repeat(1, 100%);
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .grid_item {
                width: 80%;
            }
        }

        .button {
            width: 90%;
            font-size: 10px;
        }
    }
}
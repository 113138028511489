@use '../../../../variables';

.navigation_wrapper {
    border-bottom: solid 3px variables.$app_light_green_color;
    display: inline-block;
    padding-right: 4px;
    padding-bottom: 2px;
    cursor: pointer;
    display: inline;
    font-size: 16px;
    font-weight: 500;

    .text {
        cursor: pointer;
        display: inline;
        font-size: 16px;
        font-weight: 500;
    }

    .green_text {
        color: variables.$app_light_green_color;
    }
}

@media screen and (max-width: 1000px) {
    .navigation {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        display: flex;
        padding: 4px;
    }
}

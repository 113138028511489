@use '../../../../../variables';

.section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    margin-bottom: 20px;

    .section_subtitle {
        font-size: large;
        font-weight: bold;
        color: variables.$app_green_color;
        text-transform: uppercase;
    }

    .section_data {

        .row {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 5px;
            height: fit-content;

            .data_name {
                font-size: medium;
                font-weight: bold;
                color: variables.$app_light_green_color;
                font-family: variables.$second_font_family;
            }

            .data_value {
                font-size: medium;
                font-weight: bold;
            }
        }
    }

    .edit_button {
        width: 100%;
        height: 50px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        button {
            background-color: variables.$app_light_green_color;
            text-transform: uppercase;
            border-radius: 3px;
            color: variables.$basic_white_color;
            border: none;
            cursor: pointer;
            font-weight: bold;
            width: 40%;
            height: 100%;
            padding: 10px;
            white-space: nowrap;
            font-size: 12px;
            text-align: center;
        }
    }

    .form {
        display: flex;
        flex-direction: column;
        width: 15vw;

        input:-webkit-autofill {
            -webkit-background-clip: text;
        }

        input,
        select {
            border: 2px solid variables.$app_intense_grey_color;
            border-radius: 3px;

        }

        input[type=radio] {
            accent-color: variables.$app_light_green_color;
            width: 15px;
            height: 15px;
        }

        .form_row {
            display: flex;
            flex-direction: column;
            width: 100%;
            margin-bottom: 10px;

            .error {
                border: 1px solid red;
                color: variables.$basic_red_color;
            }

            .field_name {
                margin-bottom: 1%;
                font-size: 13px;
                font-family: variables.$second_font_family;
            }

            .normal_field {
                width: 100%;
                padding: 2%;
            }

        }

        .buttons {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .save_button {
                border-radius: 4px;
                background-color: variables.$app_light_green_color;
                width: 40%;
                height: 50px;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                font-weight: bold;
                font-size: 12px;
                text-transform: uppercase;
                color: variables.$basic_white_color;
                border: none;
            }
        }

    }

}
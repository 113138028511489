@use '../../../../variables';

.search_and_filter_wrapper {
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 30px;
    margin-bottom: 10px;

    .search_wrapper {
        display: flex;
        align-items: center;
        border: 1px solid variables.$app_intense_grey_color;
        border-radius: 5px;
        height: 50px;
        width: 520px;
        padding-left: 25px;
        position: relative;

        select {
            border: none;
            outline: none;
            margin-right: 15px;
            font-size: 12px;
            font-weight: 700;
        }

        input {
            border: none;
            border-left: 1px solid variables.$app_intense_grey_color;
            text-indent: 15px;
            font-size: 12px;
            width: calc(100% - 166px);
            outline: none;
        }

        button {
            width: 50px;
            height: 50px;
            background-color: variables.$carousel_light_green_color;
            border: 1px solid variables.$carousel_light_green_color;
            border-bottom-right-radius: 5px;
            border-top-right-radius: 5px;
            outline: none;
            position: absolute;
            top: -1px;
            right: 0px;

            img {
                width: 22px;
            }
        }
    }

    .filter_wrapper {
        display: flex;
        align-items: center;

        label {
            text-transform: uppercase;
            color: variables.$carousel_light_green_color;
            position: relative;
            font-size: 12px;
            font-weight: 500;

            &::before {
                content: '';
                width: 30px;
                height: 2px;
                background-color: variables.$carousel_light_green_color;
                position: absolute;
                bottom: -3px;
                left: 1px;
            }

            &.filter_label {
                margin-left: 30px;
            }
        }

        select {
            border: none;
            outline: none;
            font-size: 12px;
            font-weight: 600;
            text-transform: uppercase;
            color: variables.$app_dark_green_color;
            margin-left: 10px;
        }
    }

    .sortBySelect {
        width: 200px;
        border: 1px solid variables.$app_intense_grey_color;
        height: 50px;
        outline: none;
        font-size: 12px;
        font-weight: 700;
        border-radius: 5px;
        padding-left: 5px;
        padding-right: 5px;
    }
}

@media screen and (max-width: 1300px) {
    .search_and_filter_wrapper {
        width: 100%;

        .search_wrapper {
            width: 70%;
            padding-left: 5px;

            .search_wrapper {
                width: 75px;
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .search_and_filter_wrapper {
        flex-direction: column;
        padding-left: 0;
        margin-top: 30px;
        width: 100%;
        margin-bottom: 0;

        .search_wrapper {
            width: 90%;
            margin-bottom: 15px;

            select {
                width: 40%;
            }
        }

        .search_wrapper,
        select {
            font-size: 13px;
        }

        .sortBySelect {
            width: 330px;
        }
    }
}

@media screen and (max-width: 576px) {
    .search_and_filter_wrapper {
        flex-wrap: wrap;

        div {
            width: 100%;

            &:first-child {
                margin-bottom: 20px;
            }
        }

        .filter_label {
            margin-left: 0 !important;
        }
    }
}
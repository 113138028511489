@use '../../variables';

.main_container {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;

    .header {}

    .footer {}

    .content {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 400px;
    }

    .confirmation_text {
        font-family: variables.$second_font_family;
        font-size: 24px;
        text-align: center;
    }
}
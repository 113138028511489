@use '../../../../variables';

.container {
    width: 100%;
    height: 100%;

    .header {
        height: 10%;
        font-size: 24px;
        font-weight: bold;
    }

    .table_container {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 90%;
        overflow-y: auto;
        margin-top: 5%;

        .row {
            display: flex;
            flex-direction: row;
            width: 100%;
            min-height: 15%;
            border-bottom: solid 1px variables.$border_color;

            .column,
            .description_column {
                width: 20%;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .product_column {
                width: 30%;
                justify-content: flex-start;
            }

            .description_column {
                font-weight: bold;
            }
        }
    }
}

@media (max-width: 768px) {
    .container {
        .header {
            font-size: 16px;
            margin-left: 15px;
        }
        .table_container {
            font-size: 12px;
            margin-left: 15px;
        }
    }
}

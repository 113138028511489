@use '../../../../variables';

.container {
    width: 95%;
    height: 95%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .section {
        border-bottom: solid 1px variables.$app_intense_grey_color;
        padding-bottom: 5%;
    }

    .summary_header {
        font-size: 24px;
        font-weight: bold;
    }

    .section_flex_row {
        display: flex;
        justify-content: space-between;
        border-bottom: solid 1px variables.$app_intense_grey_color;
        padding-bottom: 5%;

    }

    .section_bold_text {
        font-weight: bold;
    }

    .section_small_text {
        font-size: 12px;
    }
}
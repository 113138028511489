@font-face {
    font-family: 'Barlow';
    src: local('Barlow'), url(./assets/fonts/Barlow/Barlow-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Barlow';
    font-weight: bold;
    src: local('Barlow'), url(./assets/fonts/Barlow/Barlow-Bold.ttf) format('truetype');
}

@font-face {
    font-family: 'Open Sans';
    src: local('Open Sans'), url(./assets/fonts/Open-Sans/OpenSans-Medium.ttf) format('truetype');
}

$basic_black_color: #292929;
$basic_grey_color: #cacccb;
$basic_white_color: #ffffff;
$font-family: 'Barlow',
    sans-serif;
$second_font_family: 'Open Sans',
    sans-serif;
$image_icon_color: invert(98%) sepia(4%) saturate(207%) hue-rotate(4deg) brightness(95%) contrast(83%);
$basic_blue_color: #25408f;
$basic_red_color: red;
$app_red_color: #821818;
$app_green_color: #2D4913;
$footer_link_green_color: #33cc33;
$app_light_green_color: #6E8E34;
$app_dark_green_color: #193f00;
$app_orange_color: #F2B23E;
$mob_menu_bg: rgba(25, 63, 0, 0.8);
$carousel_light_green_color: #68902f;
$app_grey_color: #EBEBEB;
$app_intense_grey_color: #BBBBBB;
$border_color: rgb(235, 235, 235);
$convert_icon_to_app_light_green: invert(50%) sepia(60%) saturate(430%) hue-rotate(40deg) brightness(89%) contrast(87%);
$convert_to_white_color: brightness(0) saturate(100%) invert(100%) sepia(3%) saturate(13%) hue-rotate(56deg) brightness(105%) contrast(104%);
$convert_to_black: invert(0%) sepia(3%) saturate(0%) hue-rotate(297deg) brightness(0%) contrast(99%);
$convert_to_blue_color: brightness(0) saturate(100%) invert(31%) sepia(58%) saturate(277%) hue-rotate(131deg) brightness(98%) contrast(85%);
$cart_grey_color: #4c4c4c;
$convert_to_dark_grey: invert(34%) sepia(0%) saturate(781%) hue-rotate(262deg) brightness(87%) contrast(94%);

$input_option_accent_color: #6E8E34;
$tile_color: #6E8E34;

$zziemi_footer_background_color: #36332a;
$spizarnia24_footer_background_color: #2d4913;

$first_breakpoint: 1300px;
$second_breakpoint: 1115px;
$third_breakpoint: 894px;
$fourth_breakpoint: 750px;

$gallery_box_shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);

$gallery_box_shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
$button_red_color: #881c1c;
$light_grey_color: #F7F7F7;
$dark_grey_color: #313030;

$product_card_add_to_card_color: #f2b23e;
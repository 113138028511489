@use '../../variables';

.decor {
    width: 100%;
    height: 100px;
    background-color: variables.$app_light_green_color;
    margin-bottom: 55px;
}

.main_container {
    display: grid;
    grid-template-columns: 4fr 2fr;
    width: 100%;
    max-width: 1300px;
    font-family: variables.$font_family;
    margin: 0 auto 50px auto;

    .first_column {
        .single_field_wrapper {
            width: 100%;
            margin-bottom: 20px;

            &.error {
                input[type='text'] {
                    border: 1px solid variables.$basic_red_color;
                }
            }

            input[type='text'] {
                width: 100%;
                padding: 0.5rem;
                height: 3rem;
                border: 1px solid variables.$app_intense_grey_color;
                border-radius: 3px;
                font-size: 14px;

                &:-webkit-autofill {
                    -webkit-background-clip: text;
                }
            }
        }

        .single_field_with_label_wrapper {
            width: 100%;
            position: relative;
            margin-bottom: 20px;

            span {
                width: 25%;
                height: 10px;
                font-size: 14px;
                font-family: variables.$second_font_family;
            }

            input[type='text'] {
                width: 100%;
                padding: 0.5rem;
                height: 3rem;
                border: 1px solid variables.$app_intense_grey_color;
                border-radius: 3px;
                font-size: 14px;

                &:-webkit-autofill {
                    -webkit-background-clip: text;
                }
            }

            select {
                width: 100%;
                padding: 0.5rem;
                height: 3rem;
                border: 1px solid variables.$app_intense_grey_color;
                border-radius: 3px;
                font-size: 14px;
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
                background-repeat: no-repeat;
                background-image: url('../../assets/icons/arrow_down.svg');
                background-size: 9px;
                background-position: 96%;
                cursor: pointer;

                option {
                    background-color: variables.$app_green_color;
                    color: variables.$basic_white_color;
                }
            }

            .separator {
                position: absolute;
                height: 3rem;
                width: 1px;
                background-color: variables.$app_intense_grey_color;
                right: calc(8% + 9px);
            }

            textarea {
                width: 100%;
                min-height: 80px;
                font-size: 12px;
                padding: 0.5rem;
                min-height: 5rem;
                height: auto;
                border: 1px solid variables.$app_intense_grey_color;
                border-radius: 3px;
                resize: vertical;
            }

            &.error {
                input[type='text'] {
                    border: 1px solid variables.$basic_red_color;
                }

                select {
                    border: 1px solid variables.$basic_red_color;
                }
            }
        }

        .double_fields_wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 20px;

            .field {
                width: 49%;

                &.error {
                    input[type='text'] {
                        border: 1px solid variables.$basic_red_color;
                    }
                }

                input[type='text'] {
                    width: 100%;
                    padding: 0.5rem;
                    height: 3rem;
                    border: 1px solid variables.$app_intense_grey_color;
                    border-radius: 3px;
                    font-size: 14px;

                    &:-webkit-autofill {
                        -webkit-background-clip: text;
                    }
                }
            }
        }

        .email_error {
            margin-top: -15px;
            max-width: 425px;
        }

        .order_error{
            width: 100%;
            margin-top: -15px;
            margin-bottom: 10px;
            font-size: 14px;
            text-align: left;
        }

        .three_fields_wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;

            margin-top: 20px;

            .field {
                width: 32%;

                &.error {
                    input[type='text'] {
                        border: 1px solid variables.$basic_red_color;
                    }
                }

                input[type='text'] {
                    width: 100%;
                    padding: 0.5rem;
                    height: 3rem;
                    border: 1px solid variables.$app_intense_grey_color;
                    border-radius: 3px;
                    font-size: 14px;

                    &:-webkit-autofill {
                        -webkit-background-clip: text;
                    }
                }
            }
        }

        .checkbox_with_label {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 15px;
            margin-top: 20px;
            font-size: 14px;
            font-weight: bold;
            margin-bottom: 20px;
        }

        .radio_with_label {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            font-size: 14px;

            .delivery_price {
                color: variables.$app_light_green_color;
                margin-left: 5px;
                font-weight: 700;
            }

            input[type='radio'] {
                accent-color: variables.$app_light_green_color;
                margin-right: 50px;
            }

            &:first-child {
                .delivery_item {
                    border-top: 1px solid variables.$app_grey_color;
                }
            }
        }

        .delivery_item,
        .payment_item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            padding: 10px 0;
            border-bottom: 1px solid variables.$app_grey_color;
        }

        .payment_item {
            &:first-child {
                border-top: 1px solid variables.$app_grey_color;
            }
        }

        .delivery_details {
            padding-bottom: 20px;
            margin-bottom: 20px;
        }

        &.error {
            border: solid 1px variables.$basic_red_color;
        }

        .delivery_method {
            margin-top: 20px;
            margin-bottom: 20px;

            .radio_wrapper {
                width: 100%;

                &.error {
                    border: solid 1px variables.$basic_red_color;
                }
            }

            .checkbox_with_label {
                margin-left: 20px;
            }

            .choose_pickup_point {
                display: flex;
                justify-content: flex-start;
                flex-direction: column;
                margin-top: 10px;
                margin-bottom: 10px;
                padding: auto;

                button {
                    background-color: variables.$app_light_green_color;
                    color: variables.$basic_white_color;
                    font-weight: bold;
                    font-size: 12px;
                    border: none;
                    border-radius: 3px;
                    padding: 5px;
                    text-transform: uppercase;
                    margin-top: 10px;
                }

                .selected_pickup_point {
                    margin-top: 15px;
                    font-size: 14px;
                }
            }
        }

        .continue_button_div {
            display: flex;
            justify-content: center;
            height: 6vh;
            margin-bottom: 20px;
            width: 100%;


            .wrapper{
            width: 100%;
            display: flex;
            justify-content: space-between;
            button {
                background-color: variables.$app_light_green_color;
                text-transform: uppercase;
                border-radius: 3px;
                color: variables.$basic_white_color;
                border: none;
                cursor: pointer;
                font-weight: bold;
                width: 35%;
                height: 100%;
                padding: 10px;
                white-space: nowrap;
                font-size: 12px;
                text-align: center;

                &:disabled {
                    background-color: grey;
                    color: lightgrey;
                    cursor: not-allowed;
                    opacity: 0.6;
                }
            }
            }
        }

        .continue_button_div_2 {
            display: flex;
            justify-content: flex-end;
            height: 6vh;
            margin-bottom: 20px;
            width: 100%;

            button {
                background-color: variables.$app_light_green_color;
                text-transform: uppercase;
                border-radius: 3px;
                color: variables.$basic_white_color;
                border: none;
                cursor: pointer;
                font-weight: bold;
                width: 35%;
                height: 100%;
                padding: 10px;
                white-space: nowrap;
                font-size: 12px;
                text-align: center;

                &:disabled {
                    background-color: grey;
                    color: lightgrey;
                    cursor: not-allowed;
                    opacity: 0.6;
                }
            }
        }

        .edit_or_place_order_buttons_container {
            display: flex;
            flex-direction: row;

            .edit_or_place_order_button {
                width: 50%;
                height: 50px;
                display: flex;
                flex-direction: row;
                margin-bottom: 20px;
                justify-content: flex-end;

                button {
                    background-color: variables.$app_light_green_color;
                    text-transform: uppercase;
                    border-radius: 3px;
                    color: variables.$basic_white_color;
                    border: none;
                    cursor: pointer;
                    font-weight: bold;
                    width: 70%;
                    height: 100%;
                    padding: 10px;
                    white-space: nowrap;
                    font-size: 12px;
                    text-align: center;

                    &:disabled {
                        background-color: variables.$app_intense_grey_color;
                        cursor: not-allowed;
                    }
                }
            }

            .flex_start {
                display: flex;
                justify-content: flex-start;
            }
        }

        .privacy_and_terms_container {
            font-size: 12px;
            display: flex;
            flex-direction: column;
            gap: 20px;

            span {
                a {
                    text-decoration: none;
                }
            }

            .user_agree {
                input {
                    margin-right: 10px;
                }

                span {
                    margin-top: -3px;
                }
            }
        }
    }

    .second_column {
        margin-left: 60px;
        padding: 40px;
        border: 1px solid variables.$basic_grey_color;
        border-radius: 5px;
        margin-bottom: auto;

        .payment_method {
            border-bottom: 1px solid variables.$basic_grey_color;
            margin-bottom: 20px;

            .radio_wrapper {
                padding: 5%;

                &.error {
                    border: solid 1px variables.$basic_red_color;
                }
            }

            .edit_or_place_order_buttons_container {
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex-direction: row;
                width: 100%;
                height: 12%;
                margin-top: 10px;
            }
        }

        .order_summary {
            .review_order {
                font-size: 14px;
                padding-bottom: 10px;
            }

            .order_details {
                width: 100%;
                border-bottom: 1px solid variables.$basic_grey_color;

                .order_item_container{
                    padding-bottom: 20px;

                    .order_item {
                        display: flex;
                        justify-content: space-between;
                    }
                }

                .show_more {
                    font-size: 14px;
                    font-weight: bold;
                    padding-bottom: 3%;
                    color: variables.$app_light_green_color;
                }

                .left_container {
                    display: flex;
                    flex-direction: column;

                    span {
                        font-size: 14px;
                        line-height: 1;
                    }

                    .quantity_container {
                        border: 1px solid variables.$app_intense_grey_color;
                        border-radius: 3px;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        gap: 10px;
                        padding: 5px;
                        height: fit-content;
                        margin-top: 10px;
                        width: 80px;

                        .plus {
                            width: 20px;
                            height: 20px;
                            border-radius: 50%;
                            background-color: variables.$basic_black_color;
                            color: variables.$basic_white_color;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            cursor: pointer;
                            user-select: none;
                            line-height: 1px;
                        }

                        .minus {
                            width: 20px;
                            height: 20px;
                            border-radius: 50%;
                            background-color: variables.$app_intense_grey_color;
                            color: variables.$basic_black_color;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            cursor: pointer;
                            user-select: none;
                            line-height: 1px;
                        }

                        span {
                            font-weight: 700;
                        }
                    }
                }

                .total_container {
                    width: fit-content;
                    font-size: 12px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;

                    .remove_btn {
                        display: flex;
                        align-items: flex-end;
                        line-height: 1;
                        cursor: pointer;

                        img {
                            height: 15px;
                            width: 100%;
                            filter: variables.$convert_to_dark_grey;
                        }
                    }
                }
            }
        }

        .summary_data_container {
            padding-bottom: 20px;
            padding-top: 20px;

            .data_container {
                display: flex;
                justify-content: space-between;
                padding-bottom: 20px;
                margin-bottom: 20px;
                border-bottom: 1px solid variables.$app_intense_grey_color;
                font-size: 14px;
                font-weight: 700;

                .data_value {
                    font-weight: 700;
                    font-size: 12px;
                    color: variables.$app_light_green_color;
                }
            }
        }
    }

    //general styles
    .header_container {
        display: flex;
        align-items: center;
        font-weight: bold;
        text-transform: uppercase;
        font-size: 12px;
        margin-bottom: 20px;
        gap: 15px;

        .title {
            margin: 0;
            padding: 0;
            font-size: 24px;
        }

        &.checkbox_container {
            gap: 10px;
            font-weight: 400;
        }

        select {
            width: 100%;
            padding: 0.5rem;
            height: 3rem;
            border: 1px solid variables.$app_intense_grey_color;
            border-radius: 3px;
            font-size: 14px;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            background-repeat: no-repeat;
            background-image: url('../../assets/icons/arrow_down.svg');
            background-size: 9px;
            background-position: 96%;
            cursor: pointer;

            option {
                background-color: variables.$app_green_color;
                color: variables.$basic_white_color;
            }
        }
    }

    .sub_header_container {
        font-size: 12px;
        font-weight: 700;
        padding-bottom: 15px;
        border-bottom: 1px solid variables.$basic_grey_color;
        margin-bottom: 20px;

        &.header_whit_button {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .edit_or_place_order_button {
                button {
                    background-color: transparent;
                    border: none;
                    font-size: 12px;
                    color: variables.$app_orange_color;
                }
            }
        }
    }

    input[type='checkbox'] {
        accent-color: variables.$app_light_green_color;
        width: 14px;
        height: 14px;
    }

    label {
        font-size: 12px;
        padding-bottom: 15px;

        &.checkbox_label {
            padding-bottom: 0;
            text-transform: none;
        }
    }

    .error_border {
        border: solid 2px variables.$basic_red_color;
    }
}

@media screen and (max-width: 1200px) {
    .decor {
        height: 50px;
        margin-bottom: 35px;
    }

    .main_container {
        display: flex;
        flex-direction: column;
        padding: 0 20px 30px 20px;

        .second_column {
            width: 100%;
            margin-left: 0;

            .order_summary {
                .order_details {
                    table {
                        .table_content {
                            .image_container {
                                .image {
                                    width: 50px;
                                    height: 50px;
                                }

                                span {
                                    align-self: flex-start;
                                }
                            }

                            .quantity_container {
                                gap: 5px;
                                width: 65px;

                                .minus {
                                    width: 20px;
                                    height: 20px;
                                }

                                .plus {
                                    width: 20px;
                                    height: 20px;
                                }
                            }
                        }
                    }
                }
            }

            .coupon_container {
                .coupon_input {
                    input {
                        width: 50%;
                    }
                }
            }

            .place_order_button_container,.continue_button_div {
                padding-top: 20px;
                justify-content: center;
            }
        }
    }
}

.unavailable_item{
    color: variables.$basic_red_color;
    font-size: 12px;
}

.error_color {
    color: variables.$basic_red_color;
    border: variables.$basic_red_color;
}

.green_color {
    color: variables.$app_light_green_color;
}

.available_pickup_point {
    display: flex;
    text-align: center;
    align-items: center;

    .checkbox {
        margin-bottom: 15px;
        margin-left: 5px;
    }
}

table {
    width: 100%;

    tr {
        border-bottom: 1px solid variables.$basic_grey_color;
    }

    thead {
        text-transform: uppercase;

        th {
            font-weight: 700;
            font-size: 12px;
            color: #000;
            text-align: center;
        }
    }

    tbody {
        td {
            padding-top: 30px;
            padding-bottom: 30px;
            font-size: 12px;
        }
    }
}

.centered_td {
    text-align: center;
}

.selling_sessions_table {
    margin-top: 15px;
    margin-bottom: 15px;
}

.mt_2px {
    margin-top: 2px;
}

.pointer {
    cursor: pointer;
}

.no_pickup_points {
    font-weight: 600;
    color: variables.$basic_red_color;
}

.errorText {
    color: variables.$basic_red_color;
}

.errorText::placeholder {
    color: variables.$basic_red_color;
}

.green_buttons_div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 20px;
        button {
            background-color: variables.$app_light_green_color;
            text-transform: uppercase;
            border-radius: 3px;
            color: variables.$basic_white_color;
            border: none;
            cursor: pointer;
            font-weight: bold;
            width: 30%;
            height: 50px;
            padding: 10px;
            white-space: nowrap;
            font-size: 12px;
            text-align: center;

            &:disabled {
                background-color: variables.$app_intense_grey_color;
                cursor: not-allowed;
            }
    }
}


@media screen and (max-width: 460px) {
    .main_container {
        .green_buttons_div {
            button {
                font-size: 8px;
                width: 45%;
            }
    }
        .first_column {
            .green_buttons_div {
                    button {
                        font-size: 10px;
                        width: 45%;
                        font-size: 10px;
                    }
            }
        }
    }
}
